import { LOGIN_SERVICE_BACKEND, USER_MANAGER_SERVICE_BACKEND } from "../../BackendServicesExports";
import { buildAuthorizationConfig } from "../../Tools/HeaderTools";
import axios from "axios";

class EmailService {

    private loginServiceUrl: string;
    private userManagerServiceUrl: string;

    constructor(loginServiceUrl: string, userManagerServiceUrl: string) {
        this.loginServiceUrl = loginServiceUrl;
        this.userManagerServiceUrl = userManagerServiceUrl;
    }

    public async sendCode(emailData: any): Promise<any> {
        try {
            const response = await axios.post(`${this.loginServiceUrl}/v1/verificationCode`, emailData, buildAuthorizationConfig());
            return { succesful: true, data: response.data?.message };
        } catch (error: any) {
            console.log(error?.response?.data)
            return { succesful: false, description: error?.response?.data?.message };
        }
    }

    public async sendVerifyYourIdentityEmail(emailData: { email: string }): Promise<any> {
        try {
            const response = await axios.post(`${this.userManagerServiceUrl}/v1/emails/metamap/init`, emailData, buildAuthorizationConfig());
            return { succesful: true, data: response.data?.message };
        } catch (error: any) {
            console.log(error?.response?.data)
            return { succesful: false, description: error?.response?.data?.message };
        }
    }

    public async sendMetamapVerificationInProcessEmail(): Promise<any> {
        try {
            const response = await axios.post(`${this.userManagerServiceUrl}/v1/emails/metamap/inProcess`, {}, buildAuthorizationConfig());
            return { succesful: true, data: response.data?.message };
        } catch (error: any) {
            console.log(error?.response?.data)
            return { succesful: false, description: error?.response?.data?.message };
        }
    }
}

const emailService = new EmailService(LOGIN_SERVICE_BACKEND, USER_MANAGER_SERVICE_BACKEND);
export default emailService;