import React, { useEffect, useState } from "react";

import { CheckType } from "../../../../services/Verifiers/Verfier";
import nonEmptyVerifier from "../../../../services/Verifiers/NonEmptyVerifier";

import Validator from "../../../Validator/Validator";
import accountVerifier from "../../../../services/Verifiers/AccountVerifier";
import nonSelectedVerifier from "../../../../services/Verifiers/NonSelectedVerfier";
import zipCodeVerifier from "../../../../services/Verifiers/ZipCodeVerifier";
import digitVerfier from "../../../../services/Verifiers/DigitVerifier";
import achRoutingNumberVerifier from "../../../../services/Verifiers/ACHRoutingNumberVerifier";

interface USDProps {
  handleGetData: (data: any) => any;
  defaultValues?: any;
}

const USD: React.FC<USDProps> = ({ handleGetData, defaultValues }) => {
  const [routingNumber, setACHRoutingNumber] = useState<any>({
    type: CheckType.INIT,
    message: "",
  });
  const [accountNumber, setAccountNumber] = useState<any>({
    type: CheckType.INIT,
    message: "",
  });
  const [accountType, setAccountType] = useState<any>({
    type: CheckType.INIT,
    message: "",
  });
  const [country, setCountry] = useState<any>({
    type: CheckType.INIT,
    message: "",
  });
  const [state, setState] = useState<any>({
    type: CheckType.INIT,
    message: "",
  });
  const [city, setCity] = useState<any>({ type: CheckType.INIT, message: "" });
  const [address, setAdress] = useState<any>({
    type: CheckType.INIT,
    message: "",
  });
  const [zipCode, setZipCode] = useState<any>({
    type: CheckType.INIT,
    message: "",
  });

  const [submit, setSubmit] = useState<any>({});

  useEffect(() => {
    checkData();
  }, [submit]);

  const handleACHRoutingNumber = (target: any) => {
    //fuerza borrado en input que no es dígito, debería ser una opción para este tipo de inputs.
    target.value = /^[0-9]+$/.test(target.value) ? target.value: target.value.slice(0,-1);
    if (!digitVerfier.isValid(target.value)) return;
    let checker: any = {};
    if (target.value === "") checker = nonEmptyVerifier.check(target.value, "el ACH Routing Number");
    else checker = achRoutingNumberVerifier.check(target.value);
    setACHRoutingNumber(checker);
    saveSubmit(target.value, target.name);
  };

  const handleAccountNumber = (target: any) => {
    target.value = /^[0-9]+$/.test(target.value) ? target.value: target.value.slice(0,-1);
    if (!digitVerfier.isValid(target.value)) return;
    let checker: any = {};
    if (target.value === "")
      checker = nonEmptyVerifier.check(target.value, "el numero de cuenta");
    else checker = accountVerifier.check(target.value);
    setAccountNumber(checker);
    saveSubmit(target.value, target.name);
  };

  const handleAccountType = (target: any) => {
    let checker = nonSelectedVerifier.check(target.value, "el tipo de cuenta");
    setAccountType(checker);
    saveSubmit(target.value, target.name);
  };

  const handleZipCode = (target: any) => {
    target.value = /^[0-9]+$/.test(target.value) ? target.value: target.value.slice(0,-1);
    if (!digitVerfier.isValid(target.value)) return;
    let checker: any = {};
    if (target.value === "")
      checker = nonEmptyVerifier.check(target.value, "el Código Postal");
    else checker = zipCodeVerifier.check(target.value);
    setZipCode(checker);
    saveSubmit(target.value, target.name);
  };

  const handleNonEmpty = (target: any, setChecker: any, message: string) => {
    let checker = nonEmptyVerifier.check(target.value, message);
    setChecker(checker);
    saveSubmit(target.value, target.name);
  };

  const saveSubmit = (value: any, key: string) => {
    const copy = submit;
    copy[key] = value;
    setSubmit({
      ...submit,
      accountHolderName: `${defaultValues?.firstName} ${defaultValues?.lastName}`,
    });
  };

  const checkData = () => {
    const options = [
      routingNumber,
      accountNumber,
      accountType,
      country,
      state,
      city,
      address,
      zipCode,
    ];
    const isValid = options.every(
      (option: any) => option.type === CheckType.VALID
    );
    handleGetData({
      isValid: isValid,
      data: { ...submit },
    });
  };
  return (
    <form>
      <div>
        <label>{`Nombre del titular`}</label>
        <input
          disabled
          type="text"
          value={`${defaultValues?.firstName} ${defaultValues?.lastName}`}
        />
        <Validator showAlert={false} message={""} type={""} />
      </div>
      <div>
        <label>{`*ACH Routing Number`}</label>
        <input
          required
          value={submit.ach_routing_number}
          name={"achRoutingNumber"}
          onChange={(e) => handleACHRoutingNumber(e.target)}
        />
        <Validator
          showAlert={routingNumber.type !== CheckType.VALID}
          message={routingNumber.message}
          type={routingNumber.type}
        />
      </div>
      <div>
        <label>{`*Tipo de cuenta`}</label>
        <select
          required
          name={"accountType"}
          onChange={(e) => handleAccountType(e.target)}
        >
          <option value={""}>{"Seleccione el tipo de cuenta"}</option>
          <option value={"1"}>Cuenta De Ahorros</option>
          <option value={"2"}>Cuenta Corriente</option>
        </select>
        <Validator
          showAlert={accountType.type !== CheckType.VALID}
          message={accountType.message}
          type={accountType.type}
        />
      </div>
      <div>
        <label>{`*Número de cuenta`}</label>
        <input
          required
          name="accountNumber"
          value={submit.accountNumber}
          onChange={(e) => handleAccountNumber(e.target)}
        />
        <Validator
          showAlert={accountNumber.type !== CheckType.VALID}
          message={accountNumber.message}
          type={accountNumber.type}
        />
      </div>
      <div>
        <label>{`*Pais`}</label>
        <input
          name={"country"}
          onChange={(e) => handleNonEmpty(e.target, setCountry, "el pais")}
        />
        <Validator
          showAlert={country.type !== CheckType.VALID}
          message={country.message}
          type={country.type}
        />
      </div>
      <div>
        <label>{`*Ciudad`}</label>
        <input
          name={"city"}
          onChange={(e) => handleNonEmpty(e.target, setCity, "la ciudad")}
        />
        <Validator
          showAlert={city.type !== CheckType.VALID}
          message={city.message}
          type={city.type}
        />
      </div>
      <div>
        <label>{`*Direccion`}</label>
        <input
          name={"address"}
          onChange={(e) => handleNonEmpty(e.target, setAdress, "la direccion")}
        />
        <Validator
          showAlert={address.type !== CheckType.VALID}
          message={address.message}
          type={address.type}
        />
      </div>
      <div>
        <label>{`*Estado`}</label>
        <input
          name={"state"}
          onChange={(e) => handleNonEmpty(e.target, setState, "el estado")}
        />
        <Validator
          showAlert={state.type !== CheckType.VALID}
          message={state.message}
          type={state.type}
        />
      </div>
      <div>
        <label>{`*Código postal`}</label>
        <input
          value={submit.zipCode}
          name={"zipcode"}
          onChange={(e) => handleZipCode(e.target)}
        />
        <Validator
          showAlert={zipCode.type !== CheckType.VALID}
          message={zipCode.message}
          type={zipCode.type}
        />
      </div>
    </form>
  );
};

export default USD;
