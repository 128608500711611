
import { CheckResult, CheckType, Verifier } from "./Verfier";

class CurpVerifier implements Verifier<string>{
    public check(value: string): CheckResult {
        const regex: RegExp = /^.{18,18}$/
        if (!regex.test(value)) {
            return { message: "El CURP/RFC debe contener 18 caracteres", type: CheckType.DANGER };
        }
        return { message: "", type: CheckType.VALID };
    }
}

const curpVerifier = new CurpVerifier();
export default curpVerifier;
