
import { CheckResult, CheckType, Verifier } from "./Verfier";

class WalletVerifier implements Verifier<string>{
    public check(value: string): CheckResult {
        const regex: RegExp = /^0x[a-fA-F0-9]{40}$/g
        if (!regex.test(value)) {
            return { message: "Digita una direccion valida", type: CheckType.DANGER };
        }
        return { message: "", type: CheckType.VALID };
    }
}

const walletVerifier = new WalletVerifier();
export default walletVerifier   ;
