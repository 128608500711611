import { FC, useState, useEffect } from "react";
import { CheckType } from "../../../../services/Verifiers/Verfier";
import nonEmptyVerifier from "../../../../services/Verifiers/NonEmptyVerifier";
import accountVerifier from "../../../../services/Verifiers/AccountVerifier";
import Validator from "../../../Validator/Validator";
import ukSortCodeVerifier from "../../../../services/Verifiers/UkSortCodeVerifier";
import digitVerfier from "../../../../services/Verifiers/DigitVerifier";

interface GBPProps {
  handleGetData: (data: any) => any;
  defaultValues?: any;
}

const GBP: FC<GBPProps> = ({ handleGetData, defaultValues }) => {
  const [ukSortCode, setUkSortCode] = useState<any>({
    type: CheckType.INIT,
    message: "",
  });
  const [accountNumber, setAccountNumber] = useState<any>({
    type: CheckType.INIT,
    message: "",
  });

  const [submit, setSubmit] = useState<any>({});

  useEffect(() => {
    checkData();
  }, [submit]);

  const handleUkSortCode = (target: any) => {
    target.value = /^[0-9]{0,6}$/.test(target.value) ? target.value: target.value.slice(0,-1);
    if (!digitVerfier.isValid(target.value)) return;
    let checker: any = {};
    if (target.value === "")
      checker = nonEmptyVerifier.check(target.value, "el UK Sort Code");
    else checker = ukSortCodeVerifier.check(target.value);
    setUkSortCode(checker);
    saveSubmit(target.value, target.name);
  };

  const handleAccountNumber = (target: any) => {
    target.value = /^[0-9]{0,8}$/.test(target.value) ? target.value: target.value.slice(0,-1);
    if (!digitVerfier.isValid(target.value)) return;
    let checker: any = {};
    if (target.value === "")
      checker = nonEmptyVerifier.check(target.value, "el numero de cuenta");
    else checker = accountVerifier.checkGBP(target.value);
    setAccountNumber(checker);
    saveSubmit(target.value, target.name);
  };

  const saveSubmit = (value: any, key: string) => {
    const copy = submit;
    copy[key] = value;
    setSubmit({ ...submit });
  };

  const checkData = () => {
    const options = [ukSortCode, accountNumber];
    const isValid = options.every(
      (option: any) => option.type === CheckType.VALID
    );
    handleGetData({
      isValid: isValid,
      data: {
        ...submit,
        accountHolderName: `${defaultValues?.firstName} ${defaultValues?.lastName}`,
      },
    });
  };
  return (
    <form>
      <div>
        <label>{`Nombre del titular`}</label>
        <input
          disabled
          type="text"
          value={`${defaultValues?.firstName} ${defaultValues?.lastName}`}
        />
        <Validator showAlert={false} message={""} type={""} />
      </div>
      <div>
        <label>{`*UK Sort Code`}</label>
        <input
          value={submit.uk_sort_code}
          name={"ukSortCode"}
          onChange={(e) => handleUkSortCode(e.target)}
        />
        <Validator
          showAlert={ukSortCode.type !== CheckType.VALID}
          message={ukSortCode.message}
          type={ukSortCode.type}
        />
      </div>
      <div>
        <label>{`*Número de cuenta`}</label>
        <input
          required
          value={submit.accountNumber}
          name="accountNumber"
          onChange={(e) => handleAccountNumber(e.target)}
        />
        <Validator
          showAlert={accountNumber.type !== CheckType.VALID}
          message={accountNumber.message}
          type={accountNumber.type}
        />
      </div>
    </form>
  );
};

export default GBP;
