
import { CheckResult, CheckType, Verifier } from "./Verfier";

class InstitutionNumberVerifier implements Verifier<string>{
    public check(value: string): CheckResult {
        const regex: RegExp = /^[0-9]{3}$/
        if (!regex.test(value)) {
            return { message: "El número de institucion debe contener 3 caracteres numericos", type: CheckType.DANGER };
        }
        return { message: "", type: CheckType.VALID };
    }
}

const institutionNumberVerifier = new InstitutionNumberVerifier();
export default institutionNumberVerifier;
