import React from "react";
import "./Title.scss";

interface TitleProps {
    title: string;
    arrows: boolean[];
    backRef?: any;
    backClick?: any;
    nextRef?: any;
    nextClick?: any;
}

const Title: React.FC<TitleProps> = ({ title, arrows, backRef, nextRef, backClick, nextClick }) => {

    return (
        <div className="title">
            <h1 className="title__content">{title}</h1>
            {arrows[0] && <div className="title__btn left" ref={backRef} onClick={backClick}>&#60;</div>}
            {arrows[1] && <div className="title__btn right" ref={nextRef} onClick={nextClick}>&#62;</div>}
        </div>
    );
};

export default Title;