import { IonPage } from "@ionic/react";
import { useHistory } from "react-router";
import Security from "../../components/Account/Security/Security";
import Header from "../../components/Header/Header";
import "./SecurityPage.css";

const SecurityPage: React.FC = () => {

    const history = useHistory();

    const goDashboard = () => {
        history.push("/dashboard/config");
    };

    return (
        <IonPage>
            <Header viio={false} />
            <Security home={goDashboard} />
        </IonPage>
    )
}

export default SecurityPage;