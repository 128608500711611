import { IonCard, IonCardContent, IonCardHeader, IonCardTitle } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { Methods } from "../AuthMethods";
import userAuthMethodService from "../../../../../services/Services/UserAuthMethod/UserAuthMethod";
import Button from "../../../../Buttons/Button/Button";

interface PinProps {
    selectedMethod: any;
    onClick?: any;
}

export enum AuthState {
    ACTIVE = "Activo",
    INACTIVE = "Inactivo",
}

const Pin: React.FC<PinProps> = ({ selectedMethod }) => {
    const [pinAuthState, setPinAuthState] = useState<AuthState>(AuthState.INACTIVE);

    useEffect(() => {
        getPinAuthState();
    });

    const getPinAuthState = async () => {
        const response = await userAuthMethodService.getUserAuthMethod();
        if (response.succesful && response.data === "pin") setPinAuthState(AuthState.ACTIVE);
        else setPinAuthState(AuthState.INACTIVE);
    };

    const getCorrectLabel = () => {
        if (pinAuthState == "Activo") {
            return "Desactivar";
        }
        return "Activar";
    };

    const changePinState = (e: any) => {
        // const response = await UserManagerService.changePinAuthState("deactivate");
        if (pinAuthState == AuthState.INACTIVE) {
            setPinAuthState(AuthState.ACTIVE);
        } else {
            setPinAuthState(AuthState.INACTIVE);
        }
        e.stopPropagation();
    };

    const activatePin = async (e: any) => {
        await userAuthMethodService.activateUserAuthMethod("pin");
        e.stopPropagation();
    };

    const defaultContent = () => {
        return (
            <>
                <IonCardContent>Activa la verificacion por correo electronico</IonCardContent>
            </>
        );
    };

    const selectedMethodContent = () => {
        return (
            <>
                <form className="card_form">
                    <IonCardContent>
                        Activa la verificación por correo electrónico
                    </IonCardContent>
                    <p>Estado: {pinAuthState}</p>
                    <div className="card_button">
                        <Button isIcon={false} disable={false} label={getCorrectLabel()} onClick={activatePin} />
                    </div>
                </form>
            </>
        );
    };

    const activeContent = () => {
        return (
            <form className="card_form">
                <IonCardContent>Recibiras un codigo por correo para validar cada transaccion</IonCardContent>
                <p>Estado: {pinAuthState}</p>
                <div className="card_button"></div>
            </form>
        );
    };

    const getPinContent = () => {
        switch (pinAuthState) {
            case "Activo":
                return activeContent();
        }
        switch (selectedMethod) {
            case Methods.PIN:
                return selectedMethodContent();
            default:
                return defaultContent();
        }
    };

    return (
        <>
            <IonCard>
                <IonCardHeader>
                    <IonCardTitle>Codigo por correo electrónico</IonCardTitle>
                </IonCardHeader>
                {getPinContent()}
            </IonCard>
        </>
    );
};

export default Pin;
