import { addOutline, removeOutline, trendingUp } from "ionicons/icons";
import { IonIcon } from "@ionic/react";
import React from "react";
import "./ChargeDiscardButton.scss";

interface ChargeDiscardButtonProps {
    handleChargeButton: any;
    handleWithdrawButton: any;
}

const ChargeDiscardButton: React.FC<ChargeDiscardButtonProps> = ({handleChargeButton, handleWithdrawButton}) => {

    return (
        <div className="viio__btns">
            <div className="viio__btn" onClick={handleChargeButton}>
                <IonIcon icon={addOutline}></IonIcon>
                <p>Cargar</p>
            </div>
            <div className="viio__btn" onClick={handleWithdrawButton}>
                <IonIcon icon={removeOutline}></IonIcon>
                <p>Retirar</p>
            </div>
        </div>
    );
};

export default ChargeDiscardButton;