import { IonPage } from "@ionic/react";
import Card from "../../components/Account/Card/Card";
import "./CardPage.css";

const CardPage: React.FC = () => {
    return (
        <IonPage>
            <Card />
        </IonPage>
    )
}

export default CardPage;