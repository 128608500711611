import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { CheckResult, CheckType } from "../../../services/Verifiers/Verfier";
import { Swiper, SwiperSlide } from "swiper/react";
import { IonContent, useIonViewDidEnter } from "@ionic/react";
import { Navigation } from "swiper";
import exchangeRateService from "../../../services/Services/ExchangeRateService/ExchangeRateService";
import passwordRules from "../../../services/Verifiers/PasswordVerifier";
import LoadingView from "../../LoadingView/LoadingView";
import Validator from "../../Validator/Validator";
import PairInput from "../PairInput/PairInput";
import Button from "../../Buttons/Button/Button";
import Title from "../../Title/Title";
import COP from "../WithdrawMethods/COP/COP";
import AUD from "../WithdrawMethods/AUD/AUD";
import CAD from "../WithdrawMethods/CAD/CAD";
import ETH from "../WithdrawMethods/ETH/ETH";
import USDC from "../WithdrawMethods/USDC/USDC";
import USDT from "../WithdrawMethods/USDT/USDT";
import VIIO from "../WithdrawMethods/VIIO/VIIO";
import USD from "../WithdrawMethods/USD/USD";
import EUR from "../WithdrawMethods/EUR/EUR";
import MXN from "../WithdrawMethods/MXN/MXN";
import GBP from "../WithdrawMethods/GBP/GBP";
import "swiper/css";
import "./Withdraw.scss";
import userService from "../../../services/Services/UserService/UserService";
import transactionService from "../../../services/Services/TransactionService/TransactionService";
import IconWrong from "../../../assets/icon/config/WRONG.svg";
import IconEmail from "../../../assets/icon/config/EMAIL.svg";
import { useHistory } from "react-router";
import kycService from "../../../services/Services/KycService/KycService";
import invalidAccessIcon from "../../../assets/images/pup_land/Invalid_access.svg";
import IconSendEmail from "../../../assets/images/pup_land/Email_already.svg";

import { useDispatch } from "react-redux";
import { closeModal, triggerModal } from "../../../store/modalReducer";
import VerificationCodeInput, {
    VerficationCodeStatuses,
} from "../../Session/VerificationCodeInput/VerificationCodeInput";
import userAuthMethodService from "../../../services/Services/UserAuthMethod/UserAuthMethod";
import { validateKYCSuccessAndCompletedStatus } from "../../../services/Tools/KYCVerifierTools";

interface WithdrawProps {
    home(): void;
}

const Withdraw: React.FC<WithdrawProps> = ({ home }) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const swiperNavPrevRef = useRef<any>();
    const swiperNavNextRef = useRef<any>();
    const topRef = useRef<any>();

    const [swaperInstance, setSwiperInstance] = useState<any>();

    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);

    const [total, setTotal] = useState<{ basePrice: number; quotePrice: number }>({
        basePrice: 0,
        quotePrice: 0,
    });

    const [rateId, setRateId] = useState<number>(0);
    const [commission, setCommission] = useState<number>(0);
    const [exchangedPrice, setExhcangedPrice] = useState<string>("0");
    const [recipientCurrency, setRecipientCurrency] = useState<string>("COP");

    const [formValues, setFormValues] = useState<any>([]); // valores de formulario para payload

    const [disableButton, setDisableButton] = useState<boolean>(true);

    const [userBalance, setUserBalance] = useState<any>({});
    const [userCredentials, setUserCredentials] = useState<any>({});

    const [balanceAlert, setBalanceAlert] = useState<string>("");

    const [isLoadingBalance, setIsLoadingBalance] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [userAuth, setUserAuth] = useState<string>("");
    const [userPin, setUserPin] = useState<string>("");

    const [isLoadingConfirmation, setIsLoadingConfirmation] = useState<boolean>(false);

    const [submitValues, setSubmitValues] = useState<any>("");
    const [limits, setLimits] = useState<any>([]);

    const [transactionSummary, setTransactionSummary] = useState<any>([]);

    const [timer, setTimer] = useState<number>(0);
    const [verificationCodeStatus, setVerificationCodeStatus] = useState(VerficationCodeStatuses.CLEAN);

    const [isLoadingVerification, setIsLoadingVerification] = useState<boolean>(true);

    const [rateDecimals, setRateDecimals] = useState<number>(0);

    useIonViewDidEnter(() => {
        transactionService.updateExternalDeposit();
        if (!swaperInstance || swaperInstance.destroyed) return;
        swaperInstance.slideTo(0);
    });

    useEffect(() => {
        if (timer <= 0) return;
        setTimeout(() => setTimer(timer - 1), 1000);
    }, [timer]);

    useEffect(() => {
        getRate(recipientCurrency);
        getBalance();
        getUserCredentials();
        setSubmitValues({});
    }, [recipientCurrency]);

    useEffect(() => {
        checkLimits(recipientCurrency);
    }, [userBalance, total, recipientCurrency]);

    useEffect(() => {
        getTransactionLimits();
        transactionService.updateExternalDeposit();
    }, []);

    useEffect(() => {
        if (total.basePrice > 0 && total.quotePrice > 0) {
            setIsButtonDisabled(false);
        } else {
            setIsButtonDisabled(true);
        }
    }, [total]);

    const checkLimits = (asset: any) => {
        if (parseFloat(userBalance?.availableBalance) < total?.basePrice) {
            setBalanceAlert("Saldo insuficiente");
            return;
        }
        const limit = limits.find((item: any) => item["Assets.details"]?.symbol == asset);
        if (total?.basePrice < limit?.minLimit) {
            setBalanceAlert(`Monto mínimo: ${limit?.minLimit} USDC`);
            return;
        }
        if (total?.basePrice > limit?.maxLimit) {
            setBalanceAlert(`Monto maximo: ${limit?.maxLimit} USDC`);
            return;
        }
        setBalanceAlert("");
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        const payload: any = {
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            total: total.quotePrice,
            rateId: rateId,
            email: submitValues.email,
            payload: { ...submitValues },
        };
        const isUSDC = recipientCurrency === "USDC";
        let response: any;
        if (isUSDC) {
            payload.address = submitValues.walletAddress;
            console.log("sending payload", payload);
            response = await transactionService.createExternalWithdraw(payload, recipientCurrency);
        } else {
            response = await transactionService.createWithdraw(payload, recipientCurrency);
        }
        setIsLoading(false);

        if (response.data?.status != "Success") {
            dispatch(
                triggerModal({
                    icon: IconWrong,
                    title: "Lo sentimos",
                    label: "Entendido",
                    content: "No es posible realizar el retiro",
                    onClick: () => dispatch(closeModal()),
                })
            );
            return;
        }
        dispatch(
            triggerModal({
                icon: IconEmail,
                title: "Retiro en proceso",
                label: isUSDC ? "Continuar" : "Entendido",
                content: isUSDC
                    ? "En un momento recibirás tus fondos"
                    : "Recuerda que en máximo 2 días hábiles recibirás tus fondos.",
                onClick: () => dispatch(closeModal()),
            })
        );
        setTimeout(() => {
            history.push("/dashboard/viio");
        }, 3000);
    };

    const getTotal = (total: any) => {
        setTotal({ rateId, ...total });
    };

    const getRate = async (recipientCurrency: string) => {
        let rate;
        if (recipientCurrency === "VIIO") {
            rate = await exchangeRateService.getRate("USDC-USDC");
        } else rate = await exchangeRateService.getRate(`USDC-${recipientCurrency}`);
        if (rate) {
			console.log("rate", rate)
            setRateDecimals(rate.quoteCurrency?.details?.decimals || 2);
            setRateId(rate.rateId);
            setExhcangedPrice(rate.sellingPrice);
            setCommission(rate.commission);
        } else {
            setRateId(0);
            setExhcangedPrice("0");
            setCommission(0);
        }
    };

    const getBalance = async () => {
        setIsLoadingBalance(true);
        const response = await transactionService.getBalance();
        setUserBalance(response.data);
        setIsLoadingBalance(false);
    };

    const getTransactionLimits = async () => {
        const response = await exchangeRateService.getAssetsLimit();
        setLimits(response);
    };

    const getUserCredentials = async () => {
        const response: any = await userService.getUserCredentials();
        const user = response?.data;
        if (!user) return;
        user.documents = [];
        if (user.identificationObject) {
            user.documents?.push({ ...user.identificationObject });
        }
        if (user.identificationObject?.metamap) {
            user.documents?.unshift({ ...user.identificationObject?.metamap });
        }
        setUserCredentials(user);
    };

    const checkKycStatus = async () => {
        const kycStatus = await kycService.verifyKycStatus();
        if (!validateKYCSuccessAndCompletedStatus(kycStatus)) {
            dispatch(
                triggerModal({
                    icon: invalidAccessIcon,
                    title: "No estás verificado",
                    content: "Realiza la verificación de tu identidad para usar VIIO. Toma solo unos minutos.",
                    onClick: () => {
                        dispatch(closeModal());
                        history.push("/dashboard/config");
                    },
                })
            );
            return;
        }
        getUserAuth();
    };

    const changeSlide = (swaperInstance: any, event: string) => {
        topRef.current.scrollIntoView({ behavior: "smooth" });
        switch (event) {
            case "next":
                swaperInstance.slideNext(500);
                break;
            case "prev":
                swaperInstance.slidePrev(500);
                break;
        }
    };

    const handleRecipientCurrency = (data: string) => {
        setRecipientCurrency(data);
    };

    const getUserAuth = async () => {
        const response = await userAuthMethodService.getUserAuthMethod();
        if (!response.data) {
            dispatch(
                triggerModal({
                    icon: invalidAccessIcon,
                    title: "No es posible completar la transaccion",
                    content: "Debes configurar un método de autenticación.",
                    onClick: () => {
                        dispatch(closeModal());
                        history.push("/security");
                    },
                })
            );
            return;
        }
        setUserAuth(response.data);
        // changeSlide(swaperInstance, "next");
        setIsLoadingVerification(false);
    };

    const generatePin = async () => {
        if (timer > 0) {
            changeSlide(swaperInstance, "next");
            return;
        }

        const response = await userAuthMethodService.generateUserPin();
        if (!response.succesful) {
            dispatch(
                triggerModal({
                    icon: invalidAccessIcon,
                    title: "No se ha podido generar el código de verificación",
                    content: "Ha ocurrido un error al generar el código de verificacion.",
                    onClick: () => {
                        setVerificationCodeStatus(VerficationCodeStatuses.CLEAN);
                        return dispatch(closeModal());
                    },
                })
            );
            return;
        }
        setTimer(30);
        dispatch(
            triggerModal({
                icon: IconSendEmail,
                title: "Código de seguridad",
                content: "Hemos enviado un código de seguridad a tu correo electrónico. Verifica tu bandeja de entrada",
                label: "Entendido",
                onClick: () => {
                    setVerificationCodeStatus(VerficationCodeStatuses.CLEAN);
                    dispatch(closeModal());
                    changeSlide(swaperInstance, "next");
                },
            })
        );
    };

    const handleAuthMethod = () => {
        if (userAuth === "pin") {
            generatePin();
            return;
        }
        changeSlide(swaperInstance, "next");
    };

    const verifyUser = async () => {
        if (userAuth === "pin") {
            verifyPin();
            return;
        }
        verifyOtp();
    };

    const verifyOtp = async () => {
        const response = await userAuthMethodService.verifyUserOtp({
            otp: parseInt(userPin),
        });
        if (!response.succesful) {
            dispatch(
                triggerModal({
                    icon: invalidAccessIcon,
                    title: "Código OTP incorrecto",
                    content: "Verifica el código OTP para realizar un retiro.",
                    onClick: () => dispatch(closeModal()),
                })
            );
            return;
        }
        handleSubmit();
    };

    const verifyPin = async () => {
        const response = await userAuthMethodService.verifyUserPin({
            code: parseInt(userPin),
        });
        if (!response.succesful) {
            dispatch(
                triggerModal({
                    icon: invalidAccessIcon,
                    title: "Pin incorrecto",
                    content: "Verifica el pin para realizar un retiro.",
                    onClick: () => dispatch(closeModal()),
                })
            );
            return;
        }
        handleSubmit();
    };

    const handleGetData = (data: any) => {
        setDisableButton(!data.isValid);
        setSubmitValues(data.data);
    };

    const getAccountType = (accountType: string) => {
        let accountTypes = [
            { name: "Cuenta de Ahorros", value: "1" },
            { name: "Cuenta Corriente", value: "2" },
        ];
        let type: any = accountTypes.find((type: any) => type.value == accountType);
        return type?.name || accountType;
    };

    const handleSetSummary = () => {
        let summary: any = [
            // default
            { title: `Total a retirar`, value: `$${total.basePrice} USDC` },
            {
                title: `Tasa de cambio`,
                value: `$${exchangedPrice} USDC/${recipientCurrency}`,
            },
            {
                title: `Comisión`,
                value: `$ 0`,
            },
            {
                title: `Recibes ${recipientCurrency}`,
                value: `$${total.quotePrice} ${recipientCurrency}`,
            },
            {
                title: "Titular de la cuenta: ",
                value: submitValues.accountHolderName,
            },
            { title: "Número de cuenta: ", value: submitValues.accountNumber },
            {
                title: "Tipo de cuenta: ",
                value: getAccountType(submitValues.accountType),
            },
            // COP
            { title: "Banco", value: submitValues.bankName },
            // USD
            { title: "ACH Routing Number", value: submitValues.achRoutingNumber },
            // USDC
            { title: "Red", value: submitValues.network },
            { title: "Direccíon de Wallet", value: submitValues.walletAddress },
            // CAD
            { title: "Número de transito", value: submitValues.transitNumber },
            { title: "Número de institución", value: submitValues.institutionNumber },
            // GBP
            { title: "UK Sort Code", value: submitValues.ukSortCode },
            // AUD
            { title: "BSB Code", value: submitValues.bsbCode },
            // EUR
            { title: "IBAN", value: submitValues.iban },
            // MXN
            { title: "CLABE ", value: submitValues.transferClabe },
            { title: "CURP/RFC", value: submitValues.transferCurp },
            // common
            { title: `Ciudad`, value: submitValues.city },
            { title: `Dirección`, value: submitValues.address },
            { title: `Estado`, value: submitValues.state },
            { title: "Código postal", value: submitValues.zipcode },
        ];
        setTransactionSummary(summary);
        changeSlide(swaperInstance, "next");
    };

    const showWithdrawComponent = () => {
        switch (recipientCurrency) {
            case "COP":
                return <COP handleGetData={(data) => handleGetData(data)} defaultValues={userCredentials} />;
            case "AUD":
                return <AUD handleGetData={(data) => handleGetData(data)} defaultValues={userCredentials} />;
            case "CAD":
                return <CAD handleGetData={(data) => handleGetData(data)} defaultValues={userCredentials} />;
            case "ETH":
                return <ETH handleGetData={(data) => handleGetData(data)} />;
            case "USD":
                return <USD handleGetData={(data) => handleGetData(data)} defaultValues={userCredentials} />;
            case "EUR":
                return <EUR handleGetData={(data) => handleGetData(data)} defaultValues={userCredentials} />;
            case "USDC":
                return <USDC handleGetData={(data) => handleGetData(data)} />;
            case "USDT":
                return <USDT handleGetData={(data) => handleGetData(data)} />;
            case "VIIO":
                return <VIIO handleGetData={(data) => handleGetData(data)} />;
            case "MXN":
                return <MXN handleGetData={(data) => handleGetData(data)} defaultValues={userCredentials} />;
            case "GBP":
                return <GBP handleGetData={(data) => handleGetData(data)} defaultValues={userCredentials} />;
        }
    };

    // const getBalanceAlert = () => {
    //     if (total?.basePrice == 0) {
    //         setBalanceAlert("Monto mínimo: $1 USDC");
    //         return;
    //     }
    //     if (parseFloat(userBalance?.availableBalance) < total?.basePrice) {
    //         setBalanceAlert("Saldo insuficiente");
    //         return;
    //     }
    //     setBalanceAlert("");
    // };

    return (
        <IonContent className="ion-content">
            <div className="top-reference" ref={topRef} />
            <Swiper
                modules={[Navigation]}
                navigation={{
                    prevEl: swiperNavPrevRef.current,
                    nextEl: swiperNavNextRef.current,
                }}
                slidesPerView={1}
                spaceBetween={400}
                allowTouchMove={false}
                onInit={(swiper: any) => {
                    swiper.params.navigation.prevEl = swiperNavPrevRef.current;
                    swiper.params.navigation.nextEl = swiperNavNextRef.current;
                    swiper.navigation.init();
                    swiper.navigation.update();
                }}
                onSwiper={(swiper) => setSwiperInstance(swiper)}
            >
                <SwiperSlide>
                    <div className="swiper-slide__container">
                        <Title title={"Retirar"} arrows={[true, false]} backClick={home} />
                        <PairInput
                            disabled={isLoadingBalance}
                            userBalance={userBalance}
                            info={1}
                            senderCurrencyCallback={handleRecipientCurrency}
                            commission={commission}
                            exchangedPrice={exchangedPrice}
                            total={getTotal}
                            limits={limits}
                            rateDecimals={rateDecimals}
                        />
                    </div>
                    <div className="msg_alert">
                        <span className="warning">{balanceAlert}</span>
                    </div>
                    <div className="withdraw__btn">
                        <Button
                            isIcon={false}
                            disable={balanceAlert !== ""}
                            label={"Siguiente"}
                            onClick={() => {
                                changeSlide(swaperInstance, "next");
                                checkKycStatus();
                            }}
                        />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="swiper-slide__container">
                        <LoadingView show={isLoadingVerification} />
                        <Title
                            title="Retirar"
                            arrows={[true, false]}
                            backClick={() => {
                                changeSlide(swaperInstance, "prev");
                            }}
                        />
                        <form>{showWithdrawComponent()}</form>
                    </div>
                    <div className="withdraw__btn">
                        <Button
                            disable={disableButton || isLoadingConfirmation}
                            isIcon={false}
                            label={"Siguiente"}
                            onClick={() => handleSetSummary()}
                        />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="swiper-slide__container">
                        <Title
                            title="Confirmar información"
                            arrows={[true, false]}
                            backClick={() => changeSlide(swaperInstance, "prev")}
                        />
                        <div className="content-container">
                            <div className="user__content">
                                {transactionSummary?.map((item: any) => (
                                    <>
                                        {item.value && (
                                            <div className="user__item">
                                                <span>{item.title}</span>
                                                <span>{item.value}</span>
                                            </div>
                                        )}
                                    </>
                                ))}
                            </div>
                            <div>
                                <Button label="Confirmar y Retirar" onClick={() => handleAuthMethod()} />
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="swiper-slide__container">
                        <LoadingView show={false} />
                        <Title
                            title="Confirmar código de verificación"
                            arrows={[true, false]}
                            backClick={() => {
                                changeSlide(swaperInstance, "prev");
                            }}
                        />
                        <form>
                            <VerificationCodeInput
                                handleGetCode={(pin: string) => setUserPin(pin)}
                                length={userAuth === "pin" ? 4 : 6}
                                cleanSetCode={setVerificationCodeStatus}
                                cleanStatus={verificationCodeStatus}
                            />
                            <p
                                className={`resend-code-label ${timer > 0 ? "disabled" : ""}`}
                                onClick={() => handleAuthMethod()}
                            >
                                Obtener un nuevo código {timer ? `(${timer})` : ""}
                            </p>
                        </form>
                    </div>
                    <br />
                    <br />
                    <div className="withdraw__btn">
                        <Button disable={isLoading} isIcon={false} label={"Siguiente"} onClick={() => verifyUser()} />
                    </div>
                </SwiperSlide>
            </Swiper>
        </IonContent>
    );
};

export default Withdraw;
