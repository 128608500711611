import React, { useEffect, useState } from "react";
import Button from "../Buttons/Button/Button";
import "./Modal.scss";

interface ModalProps {
  title: string;
  show: boolean;
  label: string;
  content: string;
  secondaryContent?: string;
  onClick: any;
  disable?: boolean;
  icon?: string;
  altButtonLabel?: string;
  onAltClick?: any;
  hideButtons?: boolean;
}

const Modal: React.FC<ModalProps> = ({
  title,
  show,
  content,
  secondaryContent,
  label,
  onClick,
  disable,
  icon,
  altButtonLabel,
  onAltClick,
  hideButtons,
}) => {
  const showHideClassName = show ? "display-block" : "display-none";

  const [isButtonDisabled, setButtonDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (disable !== undefined) setButtonDisabled(disable);
  }, [disable]);

  return (
    <div className={`info-modal-container ${showHideClassName}`}>
      <div className={`info-modal`}>
        <div className="info-modal-icon">
          <img src={icon} alt={title} width="110" />
        </div>
        <div className="info-modal-content">
          <span className="info-modal-title">{title}</span>
          <span className="p justify">{content}</span>
          {secondaryContent && (
            <span className="p justify v-space">{secondaryContent}</span>
          )}
        </div>
        <div className="info-modal__btn">
          {!hideButtons && (
            <>
              <Button
                isIcon={false}
                label={label}
                onClick={onClick}
                disable={isButtonDisabled}
              />
              {/* <Button /> */}
              {altButtonLabel && (
                <>
                  <br />
                  <Button
                    isIcon={false}
                    label={altButtonLabel}
                    onClick={onAltClick}
                    disable={isButtonDisabled}
                  />
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;
