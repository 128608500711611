export interface PseTypes {
    value: string | number;
    name: string;
}

export const personTypeList = [
    { value: 2, name: 'Seleccione el tipo de persona' },
    { value: 1, name: 'Juridica' },
    { value: 0, name: 'Natural' }
];

export const documentTypeList = [
    { value: '', name: 'Selecciona el tipo de documento' },
    { value: 'CC', name: 'Cédula de ciudadanía' },
    { value: 'CE', name: 'Cédula de extranjería' },
    { value: 'NIT', name: 'Número de identificación tributaria' },
    { value: 'PASSPORT', name: 'Pasaporte' },
];

export const phonePrefixList = [
    { value: '57', name: '(+57) Colombia' },
];