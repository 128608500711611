import { buildAuthorizationConfig } from "../../Tools/HeaderTools";
import { TESTING, USER_MANAGER_SERVICE_BACKEND } from "../../BackendServicesExports";
import axios from "axios";

class UserService {
	private url: string;

	constructor(url: string) {
		this.url = url;
	}

	public async getUserCredentials(): Promise<any> {
		try {
			const { data } = await axios.get(
				`${this.url}/v1/userCredentials`,
				buildAuthorizationConfig()
			);
			return { succesful: true, data: data };
		} catch (error: any) {
			return { succesful: false, description: error?.response?.data?.message };
		}
	}

	public async getExternalUserCredentials(email: string): Promise<any> {
		try {
			const { data } = await axios.get(
				`${this.url}/v1/name?email=${email}`,
				buildAuthorizationConfig()
			);
			return { succesful: true, data: data };
		} catch (error: any) {
			return { succesful: false, description: error?.response?.data?.message };
		}
	}

	public async getUserAddress(): Promise<any> {
		try {
			const { data } = await axios.get(`${this.url}/v1/address?chain=${TESTING ? "avax" : "polygon"}&asset=USDC`, buildAuthorizationConfig());
			return { succesful: true, data: data };
		} catch (error: any) {
			return { succesful: false, description: error?.response?.data?.message };
		}
	}

	public async verifyPassword(body: any): Promise<any> {
		try {
			const { data } = await axios.post(
				`${this.url}/v1/verifyPassword`,
				body,
				buildAuthorizationConfig()
			);
			return { succesful: true, data: data };
		} catch (error: any) {
			return { succesful: false, description: error?.response?.data?.message };
		}
	}

	public async verifyAndUpdatePassword(body: any): Promise<any> {
		try {
			const { data } = await axios.post(
				`${this.url}/v1/verifyAndUpdatePassword`,
				body,
				buildAuthorizationConfig()
			);
			return { succesful: true, data: data };
		} catch (error: any) {
			return { succesful: false, error: error?.response?.data?.error };
		}
	}

	public async sendPasswordRecoveryEmail(body: any): Promise<any> {
		try {
			const { data } = await axios.post(
				`${this.url}/public/v1/passwordRecovery`,
				body
			);
			return { succesful: true, data: data };
		} catch (error: any) {
			return { succesful: false, description: error?.response?.data?.error };
		}
	}

	public async changePassword(body: any, token: string): Promise<any> {
		try {
			const { data } = await axios.put(
				`${this.url}/private/v1/password`,
				body,
				buildAuthorizationConfig(token)
			);
			return { succesful: true, data: data };
		} catch (error: any) {
			return { succesful: false, description: error?.response?.data?.message };
		}
	}

	public async verifyEmail(body: any): Promise<any> {
		try {
			const { data } = await axios.post(`${this.url}/public/v1/verify-email`, body);
			return { succesful: true, result: data.result, message: data.message };
		} catch (error: any) {
			return { succesful: false, description: error?.response?.data?.message };
		}
	}

	public async changeTutorialStatus(body: any): Promise<any> {
		try {
			await axios.put(`${this.url}/v1/tutorial/status`, body, buildAuthorizationConfig());
			return { succesful: true };
		} catch (error: any) {
			return { succesful: false };
		}
	}

	public async getTutorialStatus(): Promise<any> {
		try {
			const { data } = await axios.get(`${this.url}/v1/tutorial/status`, buildAuthorizationConfig());
			return { succesful: true, completed: data.result };
		} catch (error: any) {
			return { succesful: false };
		}
	}

	public async getTransferLinkedDetails(transactionId: number): Promise<any> {
		try {
			const { data } = await axios.get(`${this.url}/v1/transactions/${transactionId}`, buildAuthorizationConfig());
			return { succesful: true, result: data };
		} catch (error: any) {
			return { succesful: false };
		}
	}

}

const userService = new UserService(USER_MANAGER_SERVICE_BACKEND);
export default userService;
