import { CheckResult, CheckType, Verifier } from "./Verfier";

class NonSelectedVerfier implements Verifier<string>{

    public check(input: string, message: string): CheckResult {
        if (input === '') {
            return { message: `Selecciona ${message}`, type: CheckType.WARNING };
        }
        return { message: "", type: CheckType.VALID }; 
    }

}

const nonSelectedVerifier = new NonSelectedVerfier();
export default nonSelectedVerifier;
