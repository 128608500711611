
import { CheckResult, CheckType, Verifier } from "./Verfier";

class BsbCodeVerifier implements Verifier<string>{
    public check(value: string): CheckResult {
        const regex: RegExp = /^.{6,6}$/
        if (!regex.test(value)) {
            return { message: "El BSB code debe contener 6 caracteres numericos", type: CheckType.DANGER };
        }
        return { message: "", type: CheckType.VALID };
    }
}

const bsbCodeVerifier = new BsbCodeVerifier();
export default bsbCodeVerifier;
