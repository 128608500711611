export enum TransactionStatus {
    APPROVED = "APPROVED",
    SUBMITTED = "SUBMITTED",
    INSUFFICIENT_FUNDS = "INSUFFICIENT_FUNDS",
    COMPLETED = "COMPLETED",
    BLOCKED = "BLOCKED",
    FAILED = "FAILED",
    IN_TREASURY = "IN_TREASURY",
    RECEIVED = "RECEIVED",
    REJECTED = "REJECTED",
    REFUND = "REFUND"
}
export enum TransactionType {
    DEPOSIT = "DEPOSIT",
    EXTERNAL_WITHDRAWAL = "EXTERNAL_WITHDRAWAL",
    EXTERNAL_DEPOSIT = "EXTERNAL_DEPOSIT",
    REFUND = "REFUND",
    TRANSFER = "TRANSFER",
    WITHDRAWAL = "WITHDRAWAL",
    OTC_DEPOSIT = "OTC_DEPOSIT",
    OTC_WITHDRAWAL = "OTC_WITHDRAWAL"
}
export enum TransferType {
    SENDER = "sender",
    RECEIVER = "receiver"
}

