import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { IonContent, useIonViewDidEnter } from "@ionic/react";
import transactionService from "../../../services/Services/TransactionService/TransactionService";
import verification from "../../../assets/images/PROCESS.svg";
import rejected from "../../../assets/images/DENIED.svg";
import approved from "../../../assets/images/SUCCESS.svg";
import Result from "../Result/Result";
import icon from "../../../assets/images/pup_land/Frame.svg";
import "./Response.scss";

interface ResponseProps {
    changePage(): void;
}

enum TransactionStatus {
    REJECTED = "REJECTED",
    APPROVED = "APPROVED",
    SUBMITTED = "SUBMITTED",
    COMPLETED = "COMPLETED",
    RECEIVED = "RECEIVED",
    IN_TREASURY = "IN_TREASURY",
}

const Response: React.FC<ResponseProps> = ({ changePage }) => {
    let location = useLocation();

    const [tries, setTries] = useState<number>(0);
    const [showButton, setShowButton] = useState<boolean>(false);
    const [transactionStatus, setTransactionStatus] = useState<string>(TransactionStatus.RECEIVED);

    useEffect(() => {
        getReferenceFromUrl();
    }, [location.search, tries]);

    const getReferenceFromUrl = () => {
        let reference = location.search.split("=");
        if (reference[0] === "?reference") {
            getTransactionStatus(reference[1]);
        } else {
            setShowButton(true);
        }
    };

    const getTransactionStatus = async (reference: string) => {
        let transactionsResult = await transactionService.getTransactions(
            undefined,
            undefined,
            undefined,
            undefined,
            reference
        );
        if (transactionsResult.success) {
            let transaction = transactionsResult.data.transactions[0];
            if (transaction.TransactionStatus.status != TransactionStatus.RECEIVED) {
                setTransactionStatus(transaction.TransactionStatus.status);
            } else {
                if (tries < 5) {
                    setTimeout(() => {
                        setTries((prevTry) => prevTry + 1);
                    }, 3000);
                } else if(tries < 3 ) {
                    setShowButton(true);
                }
            }
        } else {
            if (tries < 3) {
                setTimeout(() => {
                    setTries((prevTry) => prevTry + 1);
                }, 3000);
            } else {
                setShowButton(true);
            }
        }
    };

    const showTransactionStatus = () => {
        switch (transactionStatus) {
            case TransactionStatus.REJECTED:
                return (
                    <>
                        <Result
                            hasTitle={true}
                            subtitle={"Transacción rechazada"}
                            image={rejected}
                            denied={true}
                            isIcon={true}
                            callbackOnClick={() => changePage()}
                            icon="&#xe90f;"
                            text={"Ha ocurrido un problema. Por favor inténtalo más tarde"}
                            showButton={true}
                            buttonText={"Entendido"}
                        />
                    </>
                );
            case TransactionStatus.SUBMITTED:
            case TransactionStatus.APPROVED:
            case TransactionStatus.COMPLETED:
            case TransactionStatus.IN_TREASURY:
                return (
                    <>
                        <Result
                            hasTitle={true}
                            subtitle={"Transacción aprobada"}
                            image={approved}
                            isIcon={false}
                            callbackOnClick={() => changePage()}
                            text={"Has cargado tu VIIO con éxito"}
                            showButton={true}
                            buttonText={"Entendido"}
                        />
                    </>
                );
            case TransactionStatus.RECEIVED:
                return (
                    <>
                        <Result
                            hasTitle={false}
                            subtitle={"Tu transacción se encuentra en proceso"}
                            image={icon}
                            isIcon={false}
                            callbackOnClick={() => changePage()}
                            text={"Por favor espera un momento"}
                            buttonText={"Entendido"}
                            showButton={showButton}
                        />
                    </>
                );
            default:
                return (
                    <Result
                        subtitle={"No se pudo resolver el estado de la transaccion"}
                        image={verification}
                        isIcon={true}
                        icon="&#x63;"
                        callbackOnClick={() => changePage()}
                        text={"Por favor espera un momento"}
                        showButton={true}
                        buttonText={"Entendido"}
                    />
                );
        }
    };

    return (
        <IonContent>
            <div>{showTransactionStatus()}</div>
        </IonContent>
    );
};

export default Response;
