import { KYC_SERVICE_BACKEND } from "../../BackendServicesExports";
import { Publisher } from "../../Publisher";
import { buildAuthorizationConfig } from "../../Tools/HeaderTools";
import axios from "axios";

export class KycService extends Publisher {
    
    private kycServiceUrl: string;

    constructor(kycServiceUrl: string) {
        super();
        this.kycServiceUrl = kycServiceUrl;
    }

    public async blockKycStatusForUser() {
        try {
            const result: any = await axios.post(`${this.kycServiceUrl}/v1/kyc/metamap/block`, {}, buildAuthorizationConfig());
            return result.data;
        } catch (error) {
            return [];
        }
    }


    public async updateKycStatusAddingValidationTry() {
        try {
            const result: any = await axios.post(`${this.kycServiceUrl}/v1/kyc/metamap/update`, {}, buildAuthorizationConfig());
            return result.data;
        } catch (error) {
            return [];
        }
    }

    public async verifyKycStatus() {
        try {
            const result: any = await axios.get(`${this.kycServiceUrl}/v1/kyc/metamap`, buildAuthorizationConfig());
            return result.data;
        } catch (error) {
            return [];
        }
    }

    public async updateKycStatusToFinished() {
        try {
            const result: any = await axios.post(`${this.kycServiceUrl}/v1/kyc/metamap/finish`, {}, buildAuthorizationConfig());
            return result.data;
        } catch (error) {
            return [];
        }
    }

}

const kycService = new KycService(KYC_SERVICE_BACKEND);
export default kycService;


