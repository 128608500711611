import { CheckResult, CheckType, Verifier } from "./Verfier";

class EmailVerfier implements Verifier<string>{

    public check(email: string): CheckResult {
        const regex: RegExp = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
        if (!regex.test(email)) {
            return { message: "Digita un email valido", type: CheckType.WARNING };
        }
        return { message: "", type: CheckType.VALID };
    }

}

const emailVerifier = new EmailVerfier();
export default emailVerifier;
