import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { CheckResult, CheckType } from "../../../services/Verifiers/Verfier";
import { gsap } from "gsap";
import nonEmptyVerifier from "../../../services/Verifiers/NonEmptyVerifier";
import showPasswordImg from "../../../assets/icon/password/show-password-light.svg";
import hidePasswordImg from "../../../assets/icon/password/hide-password-light.svg";
import loginService from "../../../services/Services/LoginService/LoginService";
import Validator from "../../Validator/Validator";
import typesymb from "../../../assets/images/LOGOTYPE.svg";
import Button from "../../Buttons/Button/Button";
import symb from "../../../assets/images/LOGOSYMB.svg";
import "./Login.scss";
import emailVerifier from "../../../services/Verifiers/EmailVerfier";
import { useIonViewDidEnter } from "@ionic/react";

interface LoginProps {
  changePage: any;
  callback?: any;
  mode?: string;
}

const Login: React.FC<LoginProps> = ({ callback, mode, changePage }) => {

  const loginInTl = gsap.timeline({ paused: true });
  const introTl = gsap.timeline({ paused: true });

  const logoRef = useRef<any>();

  const [isButtonDisabled, setButtonDisabled] = useState<boolean>(true);
  const [startAnimation, setStartAnimation] = useState<boolean>(false);

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const [emailAlert, setEmailAlert] = useState<CheckResult>({ type: CheckType.INIT, message: "" });
  const [passwordAlert, setPasswordAlert] = useState<CheckResult>({ type: CheckType.INIT, message: "" });

  const [showPassword, setShowPassword] = useState<boolean>(false)

  useIonViewDidEnter(() => setPassword(""))

  useEffect(() => {
    if (!startAnimation) return;
    initAnimation();
  }, [startAnimation])

  useEffect(() => {
    setStartAnimation(true)
    // let vh = window.innerHeight * 0.01;
    // const elem = document.querySelector('.login') as HTMLElement;
    // elem.style.setProperty('--vh', `${vh}px`);
    // window.addEventListener('resize', () => {
    //     vh = window.innerHeight * 0.01;
    //     elem.style.setProperty('--vh', `${vh}px`);
    // });
    //initAnimation();
  }, [])

  const initAnimation = () => {
    const logo = document.querySelector('.login__logo');
    logo?.classList.add('float');

    loginInTl.to('.login__logo', {
      paddingTop: '75px',
      top: 0,
      transform: 'translateY(0%)',
      duration: 1,
      delay: 1,
      onComplete: function () {
        logo?.classList.remove('float');
      }
    })
    loginInTl.fromTo('.login__elem', {
      autoAlpha: 0
    }, {
      autoAlpha: 1,
      duration: 1,
      stagger: 0.3
    }, '<1.5');
    introTl.fromTo('.login__logo-symbol', {
      autoAlpha: 0,
      y: '30px'
    }, {
      autoAlpha: 1,
      y: 0,
      duration: 0.5,
      delay: 1
    });
    introTl.fromTo('.login__logo-text', {
      autoAlpha: 0,
      y: '30px'
    }, {
      autoAlpha: 1,
      y: 0,
      duration: 0.5,
      onComplete: () => {
        loginInTl.play();
      }
    });
    introTl.play();
  };

  useEffect(() => {
    if (emailAlert.type === 'valid' && passwordAlert.type === 'valid')
      setButtonDisabled(false);
    else
      setButtonDisabled(true);
  }, [emailAlert, passwordAlert])

  const changeEmail = (value: any) => {
    setEmail(value);
    if (value === "") {
      setEmailAlert(nonEmptyVerifier.check(value, "el email"));
      return;
    }
    setEmailAlert(emailVerifier.check(value));
  };

  const changePassword = (val: any) => {
    setPassword(val.target.value);
    setPasswordAlert(nonEmptyVerifier.check(val.target.value, "la contraseña"));
  };

  const login = async () => {
    const login = await loginService.login({ email: email.toLowerCase(), password });
    setPassword("");
    callback(login);
  };

  const showAlert = (type: string) => {
    if (type === "password")
      return <Validator showAlert={passwordAlert.type !== CheckType.VALID} message={passwordAlert.message} type={passwordAlert.type} />
    if (type === "email")
      return <Validator showAlert={emailAlert.type !== CheckType.VALID} message={emailAlert.message} type={emailAlert.type} />
  }

  return (
    <>
      <div className={"login"}>
        <div className="login__home">
          <div className="login__logo" ref={logoRef}>
            <div className="login__logo-symbol">
              <img src={symb} alt="VIIO Logo" width="120"></img>
            </div>
            <div className="login__logo-text">
              <img src={typesymb} alt="VIIO Logo" width="120"></img>
            </div>
          </div>
          <div className="login__form login__elem">
            <div className="login__form-wrapper">
              <form noValidate>
                <input
                  name="email"
                  value={email}
                  type={"email"}
                  placeholder="Correo electrónico"
                  onChange={(e) => changeEmail(e.target.value)}
                />
                {showAlert("email")}
                <div className="form__password">
                  <input
                    name="password"
                    value={password}
                    autoComplete="off"
                    placeholder="Contraseña"
                    type={showPassword ? "text" : "password"}
                    onChange={changePassword}
                  />
                  <div className="icon">
                    <img
                      src={showPassword ? showPasswordImg : hidePasswordImg}
                      alt=""
                      onClick={() =>
                        setShowPassword(showPassword ? false : true)
                      }
                    />
                  </div>
                </div>
                {showAlert("password")}
              </form>
            </div>
          </div>
          <div className="login__options">
            <div className="login__elem">
              <Button
                isIcon={false}
                label="Ingresar"
                light={true}
                onClick={login}
                disable={isButtonDisabled}
              />
            </div>
            <p
              className="login__option login__elem"
              onClick={() => changePage("register")}
            >
              Regístrarse
            </p>
            <p
              className="login__option login__elem"
              onClick={() => changePage("recover")}
            >
              Recuperar contraseña
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;