import React, { useEffect, useState } from "react";
import "./Result.scss";


interface ResultProps {
    isIcon: boolean;
    subtitle: string;
    text: string;
    subText?: string;
    callbackOnClick(event?: any): void;
    showButton: boolean;
    buttonText: string;
    image?: any;
    denied?: boolean;
    warn?: boolean;
    icon?: string;
    hasTitle?: boolean;
    timer?: any;
}

const Result: React.FC<ResultProps> = ({
    callbackOnClick,
    isIcon,
    subtitle,
    buttonText,
    image,
    text,
    subText,
    showButton,
    denied,
    warn,
    icon,
    hasTitle,
    timer
}) => {

    const [timerState, setTimerState] = useState(timer)
    useEffect(()=>{
        setTimerState(timer);
    }, [timer, timerState])

    return (
        <div key={timer+text} className="main-content">
            <div className="transaction">
                {isIcon ? (
                    <div className={"transaction__icon " + (denied ? "denied" : "") + (warn ? "warn" : "")}>{icon}</div>
                ) : (
                    <object className="transaction__src" type="image/svg+xml" data={image}>
                        <img className="transaction__src" src={image} alt="statusImage" />
                    </object>
                )}
                {hasTitle ? <p className="transaction__content">Resultado de la operación:</p> : null}
                <p className={"transaction__subtitle " + (denied ? "denied" : "") + (warn ? "warn" : "")}>{subtitle}</p>
                {(timer)? <p className={"transaction__text"}>{text +" en "}<b>{timer< 0? 0: timer}</b></p> : <p className={"transaction__text"}>{text}</p> }
                {subText? <p className={"transaction__text"}>{subText}</p>: null}
                
            </div>
            {showButton ? (
                <div className="wallet-back-buttton" onClick={callbackOnClick}>
                    <span>{buttonText}</span>
                </div>
            ) : null}
        </div>
    );
};

export default Result;
