import React, { useLayoutEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { CheckResult, CheckType } from "../../../services/Verifiers/Verfier";
import { IonContent } from "@ionic/react";
import passwordRules from "../../../services/Verifiers/PasswordVerifier";
import PasswordInput from "../../PasswordInput/PasswordInput";
import userService from "../../../services/Services/UserService/UserService";
import IconEmail from "../../../assets/icon//config/SECURITY.svg";
import IconEmailAlready from "../../../assets/images/pup_land/Email_already.svg";
import Validator from "../../Validator/Validator";
import Button from "../../Buttons/Button/Button";
import Title from "../../Title/Title";
import "./ResetPassword.scss";
import "swiper/css";
import { useDispatch } from "react-redux";
import { closeModal, triggerModal } from "../../../store/modalReducer";

interface ResetPasssordProps {}

const ResetPasssord: React.FC<ResetPasssordProps> = () => {
  const history = useHistory();
  const { search } = useLocation();
  const dispatch = useDispatch();

  const [token, setToken] = useState<string>("");
  const [password, setPassword] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [passwordConfirmationAlert, setPasswordConfirmationAlert] =
    useState<CheckResult>({
      type: CheckType.INIT,
      message: "",
    });

  useLayoutEffect(() => {
    const query = new URLSearchParams(search);
    setToken(query.get("token") || "");
  }, []);

  const handlePassword = (field: any) => {
    setPassword(field);
  };

  const handlePasswordConfirmation = (field: any) => {
    setPasswordConfirmationAlert(
      passwordRules.match(password.value, field.value)
    );
  };

  const goDashboard = () => {
    history.push("/login");
  };

  const handleSubmit = async () => {
    let result = await userService.changePassword(
      { password: password.value },
      token
    );
    if (!result.succesful) {
      dispatch(
        triggerModal({
          icon: IconEmail,
          title: "No es posible actualizar la contraseña",
          content: "Intentalo mas tarde.",
          onClick: () => dispatch(closeModal()),
        })
      );
      return;
    }
    dispatch(
      triggerModal({
        icon: IconEmailAlready,
        title: "Contraseña creada",
        content:
          "Has recuperado el acceso a tu cuenta. Ingresa usando tu nueva contraseña.",
        onClick: () => {
          dispatch(closeModal());
          goDashboard();
        },
      })
    );
  };

  return (
    <IonContent className="ion-content">
      <Title
        title={"Configura tu contraseña"}
        arrows={[true, false]}
        backClick={() => goDashboard()}
      />
      <form>
        <p className="info-text">
          Debe contener al menos <br />8 caracteres, 1 mayúscula, 1 minúscula,
          <br /> 1 número y 1 caracter especial.
        </p>
        <PasswordInput
          handleChange={handlePassword}
          palceholder="Escribe tu contraseña"
        />
        <PasswordInput
          hasValidator={false}
          label="Verificación de contraseña"
          palceholder="Escribe tu contraseña"
          handleChange={handlePasswordConfirmation}
        />
        <Validator
          showAlert={passwordConfirmationAlert.type !== CheckType.VALID}
          message={passwordConfirmationAlert.message}
          type={passwordConfirmationAlert.type}
        />
        <div className="reset__button">
          <Button
            disable={
              !password.isValid ||
              passwordConfirmationAlert.type !== CheckType.VALID
            }
            isIcon={false}
            label={"Continuar"}
            onClick={() => {
              handleSubmit();
            }}
          />
        </div>
      </form>
    </IonContent>
  );
};

export default ResetPasssord;
