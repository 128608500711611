import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { IonContent } from "@ionic/react";
import { Navigation } from "swiper";
import VerificationCodeInput, { VerficationCodeStatuses } from "../VerificationCodeInput/VerificationCodeInput";
import Button from "../../Buttons/Button/Button";
import Title from "../../Title/Title";
import "./Register.scss";
import "swiper/css";

import { CheckResult, CheckType } from "../../../services/Verifiers/Verfier";
import Validator from "../../Validator/Validator";
import emailVerifier from "../../../services/Verifiers/EmailVerfier";
import nonEmptyVerifier from "../../../services/Verifiers/NonEmptyVerifier";
import passwordRules from "../../../services/Verifiers/PasswordVerifier";
import phoneVerifier from "../../../services/Verifiers/PhoneVerifier";
import registerService from "../../../services/Services/RegisterService/RegisterService";
import { useHistory } from "react-router";

import IconWrongPassword from "../../../assets/images/pup_land/Wrong_password.svg";
import IconWelcome from "../../../assets/images/pup_land/Welcome.svg";
import IconEmail from "../../../assets/images/pup_land/Email_1.svg";
import {
  documentTypeList,
  phonePrefixList,
} from "../../Transfer/PaymentMethods/PSE/types";
import countriesService from "../../../services/Services/CountriesService/CountriesService";
import emailService from "../../../services/Services/EmailService/EmailService";
import PasswordInput from "../../PasswordInput/PasswordInput";
import { useDispatch } from "react-redux";
import { closeModal, triggerModal } from "../../../store/modalReducer";
import nameVerifier from "../../../services/Verifiers/NameVerifier";
import userService from "../../../services/Services/UserService/UserService";
import nonSelectedVerifier from "../../../services/Verifiers/NonSelectedVerfier";
import SelectFilter from "../../SelectFilter/SelectFilter";

let highlightedCountries = [
  { label: "Colombia", value: "Colombia" },
  { label: "Argentina", value: "Argentina" },
  { label: "Mexico", value: "Mexico" },
];

interface RegisterProps {
  home: any;
}

const Register: React.FC<RegisterProps> = ({ home }) => {
  const [swaperInstance, setSwiperInstance] = useState<any>();

  const history = useHistory();
  const dispatch = useDispatch();

  const [documentAlert, setDocumentAlert] = useState<CheckResult>({
    type: CheckType.INIT,
    message: "",
  });
  const [documentTypeAlert, setDocumenTypetAlert] = useState<CheckResult>({
    type: CheckType.INIT,
    message: "",
  });
  const [addressAlert, setAddressAlert] = useState<CheckResult>({
    type: CheckType.INIT,
    message: "",
  });
  const [emailAlert, setEmailAlert] = useState<CheckResult>({
    type: CheckType.INIT,
    message: "",
  });
  const [nameAlert, setNameAlert] = useState<CheckResult>({
    type: CheckType.INIT,
    message: "",
  });
  const [lastNameAlert, setLastNameAlert] = useState<CheckResult>({
    type: CheckType.INIT,
    message: "",
  });
  const [phoneAlert, setPhoneAlert] = useState<CheckResult>({
    type: CheckType.INIT,
    message: "",
  });
  const [passwordConfirmationAlert, setPasswordConfirmationAlert] =
    useState<CheckResult>({ type: CheckType.INIT, message: "" });
  const [codeAlert, setCodeAlert] = useState<CheckResult>({
    type: CheckType.INIT,
    message: "",
  });

  const [submitValues, setSubmitValues] = useState<any>({});

  const [password, setPassword] = useState<any>({});
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingCode, setIsLoadingCode] = useState<boolean>(false);

  const [countriesData, setCountriesData] = useState<any>([]);
  const [countries, setCountries] = useState<any>();
  const [cities, setCities] = useState<any>();
  const [currentCountry, setCurrentCountry] = useState<string>("");
  const [currentCity, setCurrentCity] = useState<string>("");

  const [defaultCountry, setDefaultCountry] = useState<string>("");
  const [defaultCity, setDefaultCity] = useState<string>("");

  const [phonePrefix, setPhonePrefix] = useState<string>("");

  const [disableResendButton, setDisableResendButton] = useState<boolean>(true);
  const [timer, setTimer] = useState<number>(0);
  const [codeSent, setCodeSent] = useState<boolean>();

  const [passwordConfirmation, setPasswordConfirmation] = useState<any>({});

  const [verificationCodeStatus, setVerificationCodeStatus] = useState(VerficationCodeStatuses.CLEAN)

  const countryRef = useRef<any>();
  const cityRef = useRef<any>();
  const topRef = useRef<any>();

  useEffect(() => {
    if (timer === 0) {
      setDisableResendButton(false);
      setCodeSent(false);
      return;
    }
    setTimeout(() => setTimer(timer - 1), 1000);
  }, [timer]);

  useEffect(() => {
    getCountries();
  }, []);

  const getCountries = async () => {
    const response = await countriesService.getAllCountries();
    setCountriesData(response.data);
    const countries = response.data.map((item: any) => {
      return {
        label: item.country,
        value: item.country,
      };
    });
    setCountries(countries);
    setDefaultCountry("Colombia");
  };

  const handleCode = (code: string) => {
    setCodeAlert(nonEmptyVerifier.check(code, "el codigo"));
    const form = submitValues;
    form["code"] = code;
    setSubmitValues(form);
  };

  const handleDocument = (event: ChangeEvent<HTMLInputElement>) => {
    setDocumentAlert(
      nonEmptyVerifier.check(event.target.value, "el documento")
    );
    const form = submitValues;
    form["documentNumber"] = event.target.value;
    setSubmitValues({ ...form });
  };

  const handleDocumentType = (event: ChangeEvent<HTMLSelectElement>) => {
    setDocumenTypetAlert(
      nonSelectedVerifier.check(event.target.value, "el tipo de documento")
    );
    const form = submitValues;
    form["documentType"] = event.target.value;
    setSubmitValues({ ...form });
  };

  const handleAddress = (event: ChangeEvent<HTMLInputElement>) => {
    setAddressAlert(nonEmptyVerifier.check(event.target.value, "la direccion"));
    const form = submitValues;
    form["address"] = event.target.value;
    setSubmitValues({ ...form });
  };

  const handleEmail = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value === "") setEmailAlert(nonEmptyVerifier.check(value, "el email"));
    else setEmailAlert(emailVerifier.check(value));
    const form = submitValues;
    form["email"] = value.toLocaleLowerCase();
    setSubmitValues({ ...form });
  };

  const handleName = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value === "") setNameAlert(nonEmptyVerifier.check(value, "el nombre"));
    else setNameAlert(nameVerifier.check(value));
    const form = submitValues;
    form["firstname"] = value;
    setSubmitValues({ ...form });
  };

  const handleLastName = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value === "")
      setLastNameAlert(nonEmptyVerifier.check(value, "el apellido"));
    else setLastNameAlert(nameVerifier.check(value));
    const form = submitValues;
    form["lastname"] = value;
    setSubmitValues({ ...form });
  };

  const handlePhonePrefix = (event: ChangeEvent<HTMLSelectElement>) => {
    setPhonePrefix(event.target.value);
  };

  const handlePhone = (event: ChangeEvent<HTMLInputElement>) => {
    setPhoneAlert(phoneVerifier.check(event.target.value));
    const form = submitValues;
    form["phone"] = event.target.value;
    setSubmitValues({ ...form });
  };

  const handlePassword = (field: any) => {
    setPassword(field);
    const form = submitValues;
    form["password"] = field.value;
    setSubmitValues({ ...form });
    if (passwordConfirmation.value)
      handlePasswordConfirmation({ ...passwordConfirmation }, form);
  };

  const handlePasswordConfirmation = (field: any, form?: any) => {
    console.log("field", field, "old", password);
    setPasswordConfirmation(field);
    setPasswordConfirmationAlert(
      passwordRules.match(form ? form.password : password.value, field.value)
    );
  };

  const handleSetCountry = (country: any) => {
    setCurrentCity("");
    setCurrentCountry(country);
    const item = countriesData.find((item: any) => item.country == country);
    const cities = item.cities?.map((item: any) => {
      return { label: item, value: item };
    });
    setDefaultCity("");
    if (item.country == "Colombia") {
      cities.unshift({ label: "Medellín", value: "Medellín" });
      cities.unshift({ label: "Bogotá D.C", value: "Bogotá D.C" });
    }
    setCities(cities);
  };

  const handleSetCity = (option: any) => {
    setCurrentCity(option);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const data = {
      email: submitValues.email,
      password: submitValues.password,
      firstName: submitValues.firstname,
      lastName: submitValues.lastname,
      phone: submitValues.phone,
      code: submitValues.code,
      metadata: {
        countryId: "col",
        identificationObject: {
          type: submitValues.documentType,
          number: submitValues.documentNumber,
          prefix: phonePrefix,
        },
        address: {
          city: currentCity,
          address: submitValues.address,
          country: currentCountry,
        },
      },
    };
    const response = await registerService.register(data);
    const isCompleted = response.succesful;
    const responses: any = {
      "User already registered": "Lo sentimos. Ya existe una cuenta asociada a este correo electrónico. Por favor intenta nuevamente.",
      "Invalid code": "El código no coincide",
    };
    dispatch(
      triggerModal({
        icon: isCompleted ? IconWelcome : IconWrongPassword,
        title: isCompleted ? "¡Bienvenido!" : "Lo sentimos",
        content: isCompleted
          ? "Ahora eres parte de la comunidad VIIO. Ingresa con tus credenciales."
          : responses[response.description] ||
          "Ahora no es posible realizar el registro. Intenta nuevamente más tarde.",
        onClick: () => { dispatch(closeModal()); isCompleted && emailService.sendVerifyYourIdentityEmail({ email: submitValues.email }) },
      })
    );
    setIsLoading(false);
    if (isCompleted) {
      setTimeout(() => {
        history.push("/login");
      }, 3000);
    }
  };

  const handleSendCode = async () => {
    if (codeSent) {
      changeSlide(swaperInstance, "next");
      return;
    }
    setIsLoadingCode(true);
    setDisableResendButton(true);
    const data = { email: submitValues.email };
    const response = await emailService.sendCode(data);
    setIsLoadingCode(false);
    setCodeSent(true);

    const isRegistered = response.data?.includes("registered");
    if (!response.succesful || isRegistered) {
      dispatch(
        triggerModal({
          icon: IconWrongPassword,
          title: "Lo sentimos",
          content: isRegistered
            ? "Lo sentimos. Ya existe una cuenta asociada a este correo electrónico. Por favor intenta nuevamente."//`El correo ${submitValues.email} ya se encuentra registrado.`
            : "Ahora no es posible realizar el registro.",
          onClick: () => { setVerificationCodeStatus(VerficationCodeStatuses.CLEAN); return dispatch(closeModal()) },
        })
      );
      return;
    }
    dispatch(
      triggerModal({
        icon: IconEmail,
        title: "Código de confirmación",
        content: "Enviaremos un código a tu correo electrónico para verificar que tienes acceso.",
        label: "Entendido",
        onClick: () => {
          setVerificationCodeStatus(VerficationCodeStatuses.CLEAN);
          changeSlide(swaperInstance, "next");
          dispatch(closeModal());
        },
      })
    );
    setTimer(30);
  };

  const handleVerifyEmail = async () => {
    setIsLoadingCode(true);
    const response = await userService.verifyEmail({
      email: submitValues.email,
    });
    setIsLoadingCode(false);
    if (!response.result) {
      dispatch(
        triggerModal({
          icon: IconWrongPassword,
          title: "Correo en uso",
          content: "Lo sentimos. Ya existe una cuenta asociada a este correo electrónico. Por favor ingresa un correo diferente.",
          label: "Entendido",
          onClick: () => dispatch(closeModal()),
        })
      );
      return;
    }
    changeSlide(swaperInstance, "next");
  };

  const swiperNavPrevRef = useRef<any>();
  const swiperNavNextRef = useRef<any>();

  const changeSlide = (swaperInstance: any, event: string) => {
    topRef.current.scrollIntoView();
    switch (event) {
      case "next":
        swaperInstance.slideNext(500);
        break;
      case "prev":
        swaperInstance.slidePrev(500);
        break;
    }
  };

  const handleCountryFocus = () => {
    countryRef.current.scrollIntoView();
  };

  const handleCityFocus = () => {
    cityRef.current.scrollIntoView();
  };

  return (
    <IonContent className="ion-content" scrollEvents={true}>
      <div className="focus-reference-top" ref={topRef} />
      <Swiper
        modules={[Navigation]}
        navigation={{
          prevEl: swiperNavPrevRef.current,
          nextEl: swiperNavNextRef.current,
        }}
        slidesPerView={1}
        spaceBetween={400}
        allowTouchMove={false}
        onInit={(swiper: any) => {
          swiper.params.navigation.prevEl = swiperNavPrevRef.current;
          swiper.params.navigation.nextEl = swiperNavNextRef.current;
          swiper.navigation.init();
          swiper.navigation.update();
        }}
        onSwiper={(swiper) => setSwiperInstance(swiper)}
      >
        <SwiperSlide>
          <div className="swiper-slide__container">
            <Title
              title={"Ingresa tu correo electrónico"}
              arrows={[true, false]}
              backClick={home}
            />
            <form>
              <p className="info-text">
                A este correo se asociará tu billetera. Por favor verifica que
                esté escrito correctamente antes de continuar.
              </p>
              <label>Correo electrónico</label>
              <input
                placeholder="abc@email.com"
                type="email"
                id="sign-up_email"
                name="sign-up_email"
                onChange={handleEmail}
              />
              <Validator
                showAlert={emailAlert.type !== CheckType.VALID}
                message={emailAlert.message}
                type={emailAlert.type}
              />
            </form>
          </div>
          <div className="sing-up__btn">
            <div className="checkbox">
              <input
                onChange={(e) => setTermsAccepted(e.target.checked)}
                type="checkbox"
                id="sign_up_terms"
                name="sign_up_terms"
              />
              <label className="form__checkbox-label">
                He leído y acepto los{" "}
                <a
                  className="show_terms_and_conditions"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://drive.google.com/file/d/13Zft4jiTZxBstWta6gZISYF4D5yGDpey/view?usp=drive_link"
                >
                  Términos y Condiciones
                </a>
              </label>
            </div>
            <div>
              <Button
                disable={
                  emailAlert.type !== CheckType.VALID ||
                  !termsAccepted ||
                  isLoadingCode
                }
                isIcon={false}
                label={"Continuar"}
                onClick={() => handleVerifyEmail()}
              />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="swiper-slide__container">
            <Title
              title={"Datos Personales"}
              arrows={[true, false]}
              backClick={() => {
                changeSlide(swaperInstance, "prev");
              }}
            />
            <form>
              <label>*Tipo de documento</label>
              <select className="bx--text-input" onChange={handleDocumentType}>
                {documentTypeList?.map((item: any, idx: number) => (
                  <option key={`documentType-${idx}`} value={item.value}>{item.name}</option>
                ))}
              </select>
              <Validator
                showAlert={documentTypeAlert.type !== CheckType.VALID}
                message={documentTypeAlert.message}
                type={documentTypeAlert.type}
              />
              <label>*Documento</label>
              <input
                type={submitValues['documentType'] == 'PASSPORT'? "text":"number"}
                autoComplete="off"
                onChange={handleDocument}
              />
              <Validator
                showAlert={documentAlert.type !== CheckType.VALID}
                message={documentAlert.message}
                type={documentAlert.type}
              />
              <label>*Nombre(s)</label>
              <input type="text" autoComplete="off" onChange={handleName} />
              <Validator
                showAlert={nameAlert.type !== CheckType.VALID}
                message={nameAlert.message}
                type={nameAlert.type}
              />
              <label>*Apellido(s)</label>
              <input type="text" autoComplete="off" onChange={handleLastName} />
              <Validator
                showAlert={lastNameAlert.type !== CheckType.VALID}
                message={lastNameAlert.message}
                type={lastNameAlert.type}
              />
              <label>*Celular</label>
              <div className="form__phone">
                <select
                  className="bx--text-input"
                  id="select-country-phone"
                  onChange={handlePhonePrefix}
                >
                  {phonePrefixList?.map((item: any, idx: number) => (
                    <option key={`phonePrefix-${idx}`} value={item.value}>{item.name}</option>
                  ))}
                </select>
                <div className="form__phone-divider" />
                <input
                  type="number"
                  autoComplete="off"
                  onChange={handlePhone}
                />
              </div>
              <Validator
                showAlert={phoneAlert.type !== CheckType.VALID}
                message={phoneAlert.message}
                type={phoneAlert.type}
              />
              <SelectFilter
                items={countries}
                label={"Pais"}
                onSelect={(item: any) => handleSetCountry(item.value)}
                defaultValue={"Colombia"}
                scrollIntoView
                highlightedItems={highlightedCountries}
              />
              <Validator showAlert={false} message={""} type={""} />
              <SelectFilter
                items={cities}
                label={"Ciudad"}
                onSelect={(item: any) => setCurrentCity(item.value)}
                scrollIntoView
              />
              <Validator showAlert={false} message={""} type={""} />
              <label>*Dirección</label>
              <input
                type="text"
                autoComplete="off"
                onChange={handleAddress}
                placeholder="Direccion"
              />
              <Validator
                showAlert={addressAlert.type !== CheckType.VALID}
                message={addressAlert.message}
                type={addressAlert.type}
              />
            </form>
          </div>
          <div className="sing-up__btn">
            <div className="mt">
              <Button
                disable={
                  nameAlert.type !== CheckType.VALID ||
                  lastNameAlert.type !== CheckType.VALID ||
                  phoneAlert.type !== CheckType.VALID ||
                  addressAlert.type !== CheckType.VALID ||
                  currentCity == "" ||
                  currentCountry == "" ||
                  !submitValues["documentType"]
                }
                isIcon={false}
                label={"Continuar"}
                onClick={() => {
                  changeSlide(swaperInstance, "next");
                }}
              />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="swiper-slide__container">
            <Title
              title={"Configura tu contraseña"}
              arrows={[true, false]}
              backClick={() => {
                changeSlide(swaperInstance, "prev");
              }}
            />
            <form>
              <p className="info-text">
                Debe contener al menos <br />8 caracteres, 1 mayúscula, 1
                minúscula,
                <br /> 1 número y 1 caracter especial.
              </p>
              <PasswordInput handleChange={handlePassword} />
              <PasswordInput
                hasValidator={false}
                label="Confirma tu contraseña"
                handleChange={handlePasswordConfirmation}
              />
              <Validator
                showAlert={passwordConfirmationAlert.type !== CheckType.VALID}
                message={passwordConfirmationAlert.message}
                type={passwordConfirmationAlert.type}
              />
            </form>
          </div>
          <div className="sing-up__btn">
            <Button
              disable={
                !password.isValid ||
                passwordConfirmationAlert.type !== CheckType.VALID ||
                isLoadingCode
              }
              isIcon={false}
              label={"Continuar"}
              onClick={() => {
                handleSendCode();
              }}
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="swiper-slide__container">
            <Title
              title={"Ingresa tu código"}
              arrows={[true, false]}
              backClick={() => {
                changeSlide(swaperInstance, "prev");
              }}
            />
            <form>
              <p className="info-text">
                Ingresa el código de confirmación enviado a "
                {submitValues.email}"
              </p>
              <p></p>
              <div className="cVerification">
                <div
                  className={`resend-label ${disableResendButton ? "disabled" : ""
                    }`}
                >
                  <span onClick={() => handleSendCode()}>
                    Obtener un nuevo código {timer ? `(${timer})` : ""}
                  </span>
                </div>
                <VerificationCodeInput
                  length={6}
                  handleGetCode={(code: string) => handleCode(code)}
                  cleanSetCode={setVerificationCodeStatus}
                  cleanStatus={verificationCodeStatus}
                />
                <Validator
                  showAlert={codeAlert.type !== CheckType.VALID}
                  message={codeAlert.message}
                  type={codeAlert.type}
                />
              </div>
            </form>
          </div>
          <div className="sing-up__btn">
            <Button
              disable={isLoading || codeAlert.type !== CheckType.VALID}
              isIcon={false}
              label={"Continuar"}
              onClick={() => handleSubmit()}
            />
          </div>
        </SwiperSlide>
      </Swiper>
      <div style={{ height: 1000 }} />
    </IonContent>
  );
};

export default Register;
