import { IonContent } from "@ionic/react";
import React from "react";
import card from "../../../assets/images/card/card.svg";
import "./Card.scss";

interface CardProps {
    securityButton?: any;
}

const Card: React.FC<CardProps> = () => {

    return (
        <IonContent>
            <div className="card">
                <img className="img" src={card} alt="Card" width="250" ></img>
                <p className="title">Próximamente</p>
                <p>
                    Estamos desarrollando el mejor<br></br>
                    producto para que hagas uso de<br></br>
                    tu capital sin barreras, como un<br></br>
                    ciudadano global.
                </p>
            </div>
        </IonContent>
    );
};

export default Card;