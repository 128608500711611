import { CheckResult, CheckType, Verifier } from "./Verfier";

class NameVerifier implements Verifier<string> {
  public check(value: string): CheckResult {
    const regex: RegExp = /^[ a-zA-ZÀ-ÿ\00f1\00d1]*$/;
    if (regex.test(value)) {
      return { message: "", type: CheckType.VALID };
    }
    return {
      message: "El nombre no cumple las condiciones",
      type: CheckType.DANGER,
    };
  }
}

const nameVerifier = new NameVerifier();
export default nameVerifier;
