export type Subscriber = (event: any) => void;

export class Publisher {

    private observers: Subscriber[] = [];

    public attach(observer: Subscriber): void {
        if (!this.observers.includes(observer))
            this.observers.push(observer);
    }

    public detach(observer: Subscriber): void {
        const observerIndex = this.observers.indexOf(observer);
        if (observerIndex !== -1)
            this.observers.splice(observerIndex, 1);
    }

    public notify(event: any): void {
        this.observers.forEach(observer => observer(event));
    }

}

