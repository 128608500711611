import { caretForwardOutline } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';
import React from "react";
// import styles from './ButtonLink.scss';
import "./ButtonLink.scss";

interface ButtonLinkProps {
    mode: string;
    label: string;
    outline?: string;
    both?: boolean;
    subLabel?: string;
    status?: string;
    info?: string;
    subInfoType?: string;
    subInfoData?: string;
    src?: string | any;
    icon?: string;
    disable?: boolean;
    onClick?: any;
}

const ButtonLink: React.FC<ButtonLinkProps> = ({ mode, label, info, subInfoType, subInfoData, icon, src, subLabel, disable, outline, both, onClick }) => {
    return (
        <div className={"button-link" + (disable ? " button--disable" : "") + (mode === 'list' ? " list" : "") + (mode === 'info' ? " info" : "") + (mode === 'link' ? " link" : "") + (` ${outline}`)} onClick={onClick}>
            {mode !== "info" && <div className={"button-link__icon " + (subInfoType ? subInfoType : "denied")}>{icon}</div>}
            {mode === "info" && <img className="img" src={src} alt=""></img>}
            <div className="button-link__text">
                <p className="button-link__text-label">{label}</p>
                {mode !== "link" && (<p className="button-link__text-sublabel">{subLabel}</p>)}
            </div>
            <div className={`button-link__data ${mode === "list" ? "data-list" : ""}`}>
                {mode === "link" && <IonIcon className="button-link__data-arrow" name={caretForwardOutline}></IonIcon>}
                {mode === "info" && <p className="button-link__data-info">{info}</p>}
                {mode === "list" && <p className="button-link__data-info">{info}</p>}
                {mode === "list" && subInfoData && <p className={`button-link__data-info ` + (subInfoType ? subInfoType : "denied")}>{subInfoData}</p>}
            </div>
        </div>
    );
};

export default ButtonLink;