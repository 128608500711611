import { IonPage } from "@ionic/react";
import { useHistory } from "react-router";
import Header from "../../components/Header/Header";
import Wallet from "../../components/Wallet/Wallet";

const WalletPage: React.FC = () => {

    const history = useHistory();

    const handleReturn = () => {
        history.push("/dashboard/config");
    };

    return (
        <IonPage>
            <Header viio={false} />
            <Wallet onReturn={handleReturn} />
        </IonPage>
    )
}

export default WalletPage;