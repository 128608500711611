
import { CheckResult, CheckType, Verifier } from "./Verfier";

class ACHRoutingNumberVerifier implements Verifier<string>{
    public check(value: string): CheckResult {
        const regex: RegExp = /^\d{9,9}$/
        if (!regex.test(value)) {
            return { message: "El ACH Routing Number debe tener 9 caracteres numéricos", type: CheckType.DANGER };
        }
        return { message: "", type: CheckType.VALID };
    }
}

const achRoutingNumberVerifier = new ACHRoutingNumberVerifier();
export default achRoutingNumberVerifier;
