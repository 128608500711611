import React, { useEffect, useRef, useState } from "react";
import "swiper/css";
import "./TransferOptions.scss";

import { IonModal } from "@ionic/react";

import IconSend from "../../../assets/icon/Transactions/SEND.svg";
import IconReceive from "../../../assets/icon/Transactions/RECEIVE.svg";

interface Props {
  isOpen: boolean;
  onClose: any;
  onRedirect: any
}

const TransferOptions: React.FC<Props> = ({ isOpen, onClose, onRedirect }) => {
  return (
    <>
      <IonModal
        isOpen={isOpen}
        onWillDismiss={onClose}
        className="options-modal"
        mode="ios"
        initialBreakpoint={0.24}
        breakpoints={[0, 0.24]}
      >
        <div className="modal-content">
          <div className="option" onClick={() => onRedirect("/transfer")}>
            <img src={IconSend} alt="" />
            <div className="description">
              <span>Enviar</span>
              <span>A un Amigo VIIO</span>
            </div>
          </div>
          <div className="option" onClick={() => onRedirect("/wallet?options=true")}>
            <img src={IconReceive} alt="" />
            <div className="description">
              <span>Recibir USDC</span>
              <span>Desde red Polygon</span>
            </div>
          </div>
        </div>
      </IonModal>
    </>
  );
};

export default TransferOptions;
