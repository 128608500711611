import { IonPage } from "@ionic/react";
import { useHistory } from "react-router";
import Response from "../../components/Transfer/Response/Response";
import Header from "../../components/Header/Header";


const ResponsePage: React.FC = () => {

    const history = useHistory();

    const goDashboard = () => {
        history.push("/dashboard/activity");
    };

    return (
        <IonPage>
            <Header viio={false} />
            <Response changePage={goDashboard} />
        </IonPage>
    )
}

export default ResponsePage;