import { IonPage, useIonViewDidEnter, useIonViewDidLeave } from "@ionic/react";
import { useHistory } from "react-router";
import Header from "../../components/Header/Header";
import Transfer from "../../components/Transfer/Transfer/Transfer";
import { useState } from "react";

const TransferPage: React.FC = () => {
  const history = useHistory();
  const [render, setRender] = useState<boolean>(false);

  const goDashboard = () => history.push("/dashboard/viio");

  useIonViewDidLeave(() => setRender(false));
  useIonViewDidEnter(() => setRender(true));

  return (
    <IonPage>
      <Header viio={false} />
      {render && <Transfer home={goDashboard} />}
    </IonPage>
  );
};

export default TransferPage;
