import { TransactionStatus, TransactionType } from "../../types/transactionTypes";

export function getTransactionStatusInformation(status: TransactionStatus) {
    switch (status) {
        case TransactionStatus.RECEIVED:
            return { status: "Recibida", type: "add" };
        case TransactionStatus.APPROVED:
            return { status: "Aprobada", type: "add" };
        case TransactionStatus.SUBMITTED:
            return { status: "En proceso", type: "waiting", color: "orange" };
        case TransactionStatus.INSUFFICIENT_FUNDS:
            return { status: "En proceso", type: "waiting", color: "orange" };
        case TransactionStatus.COMPLETED:
            return { status: "Completada", type: "add" };
        case TransactionStatus.REJECTED:
        case TransactionStatus.REFUND:
            return { status: "Rechazada", type: "denied", color: "gray" };
        case TransactionStatus.FAILED:
            return { status: "Fallida", type: "denied", color: "gray" };
        case TransactionStatus.IN_TREASURY:
            return { status: "En Proceso", type: "waiting", color: "green" };
        case TransactionStatus.BLOCKED:
            return { status: "Bloqueada", type: "denied", color: "orange" };
        default:
            return { status: "Desconocida", type: "add" };
    }
};

export function getTransactionTypeInformation(type: TransactionType) {
    switch (type) {
        case TransactionType.WITHDRAWAL:
            return { type: "Retiro", icon: "D" };
        case TransactionType.DEPOSIT:
            return { type: "Depósito", icon: "E" };
        case TransactionType.OTC_DEPOSIT:
            return { type: "Deposito OTC", icon: "E" };
        case TransactionType.OTC_WITHDRAWAL:
            return { type: "Retiro OTC", icon: "D" };
        case TransactionType.TRANSFER:
            return { type: "Transferencia", icon: "B" };
        case TransactionType.EXTERNAL_DEPOSIT:
            return { type: "Depósito Externo", icon: "E" };
        case TransactionType.EXTERNAL_WITHDRAWAL:
            return { type: "Retiro Externo", icon: "D" };
        case TransactionType.REFUND:
            return { type: "Reembolso", icon: "E" };
        default:
            return { type: "Deposito Externo", icon: "E" };
    }
};

export const NumberFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
});

export function formatCurrency(currency: any) {
    return NumberFormatter.format(currency) || currency;
};
