import { FC, useState, useEffect } from "react";

import { CheckType } from "../../../../services/Verifiers/Verfier";
import nonEmptyVerifier from "../../../../services/Verifiers/NonEmptyVerifier";
import emailVerifier from "../../../../services/Verifiers/EmailVerfier";
import Validator from "../../../Validator/Validator";

interface VIIOProps {
  handleGetData: (data: any) => any;
}

const VIIO: FC<VIIOProps> = ({ handleGetData }) => {
  const [email, setEmail] = useState<any>({ type: CheckType.INIT, message: ""});
  const [submit, setSubmit] = useState<any>({});

  useEffect(() => {
    checkData();
  }, [submit])

  const handleEmail = (target: any) => {
    let checker: any = {};
    if (target.value === "") checker = nonEmptyVerifier.check(target.value, "el email");
    else checker = emailVerifier.check(target.value);
    setEmail(checker);
    saveSubmit(target.value, target.name)
  };

  const saveSubmit = (value: any, key: string) => {
    const copy = submit;
    copy[key] = value;
    setSubmit({...submit})
  }

  const checkData = () => {
    const options = [email];
    const isValid = options.every((option: any) => option.type === CheckType.VALID);
    handleGetData({
      isValid: isValid,
      data: { ...submit }
    })
  }

  return (
    <form>
      <div>
        <label>{`*Correo electronico del destinatario`}</label>
        <input
          name={"email"}
          onChange={(e) => handleEmail(e.target)}
          placeholder="Correo Electrónico"
        />
        <Validator
          showAlert={email.type !== CheckType.VALID}
          message={email.message}
          type={email.type}
        />
      </div>
    </form>
  );
};

export default VIIO;
