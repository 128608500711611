import { IonPage } from "@ionic/react";
import Header from "../../components/Header/Header";
import "./User.css";
import User from "../../components/Account/User/User";

const UserPage: React.FC = () => {

    return (
        <IonPage>
            <Header viio={false} />
            <User />
        </IonPage>
    )
}

export default UserPage;