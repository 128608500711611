import { IonPage } from "@ionic/react";
import Header from "../../components/Header/Header";
import RecoverPassword from "../../components/Session/RecoverPassword/RecoverPassword";

const RecoverPasswordPage: React.FC = () => {
  return (
    <IonPage>
      <Header viio={false} />
      <RecoverPassword />
    </IonPage>
  );
};

export default RecoverPasswordPage;
