import { useEffect, useState, FC } from "react";
import {
  IonContent,
  IonToast,
  useIonViewDidEnter,
  IonSkeletonText,
} from "@ionic/react";
import { Clipboard } from "@capacitor/clipboard";
import "./Wallet.scss";
import Title from "../Title/Title";
import QRCode from "react-qr-code";
import userService from "../../services/Services/UserService/UserService";
import LoadingView from "../LoadingView/LoadingView";

import IconDenied from "../../assets/images/DENIED.svg";
import kycService from "../../services/Services/KycService/KycService";
import { VerficationStatus } from "../../services/Services/KycService/KycModels";
import { TESTING } from "../../services/BackendServicesExports";

interface Props {
  onReturn?: any;
}

const Wallet: FC<Props> = ({ onReturn }) => {
  const [address, setAddress] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingAddress, setIsLoadingAddress] = useState<boolean>(false);
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [verificationStatus, setVerificationStatus] =
    useState<VerficationStatus>(VerficationStatus.PENDING);
    
  useIonViewDidEnter(() => {
    getAddress();
    verifyStatus();
  });

  useEffect(() => {
    verifyStatus(true);
    getAddress(true);
  }, []);

  const getAddress = async (loader?: boolean) => {
    if (loader) setIsLoadingAddress(true);
    const response: any = await userService.getUserAddress();
    setAddress(response?.data?.address);
    setIsLoadingAddress(false);
  };

  const verifyStatus = async (loader?: boolean) => {
    if (loader) setIsLoading(true);
    const { status } = await kycService.verifyKycStatus();
    setVerificationStatus(status);
    setIsLoading(false);
  };

  const handleCopy = async () => {
    await Clipboard.write({
      string: address,
    });
    setOpenToast(true);
  };

  return (
    <IonContent className="ion-content">
      <Title
        title="Dirección USDC"
        arrows={[true, false]}
        backClick={onReturn}
      />

      {isLoading ? (
        <div className="loading-container">
          <LoadingView show ignoreContainer />
        </div>
      ) : (
        <>
          <div className="main-content">
            {verificationStatus !== VerficationStatus.VERIFIED ? (
              <div className="error-view">
                <div className="error-image">
                  <img src={IconDenied} />
                </div>
                <div className="error-text">
                  <span>Resultado de la operación:</span>
                  <span>No estás verificado</span>
                </div>
                <div className="error-description">
                  <span>
                    Realiza la verificación de tu identidad para usar VIIO. Toma
                    solo unos minutos.
                  </span>
                </div>
              </div>
            ) : (
              <div className="wallet-container">
                <div className="wallet-content">
                  <div className="qr-container">
                    <QRCode
                      value={address}
                      style={{ height: "auto", width: "100%" }}
                    />
                  </div>
                  <div className="wallet-item">
                    <b>Red</b>
                    <span>{TESTING ? "Avax" : "Polygon"}</span>
                  </div>
                  <div className="wallet-item">
                    <b>Dirección de billetera</b>
                    <div
                      className="address-container"
                      onClick={() => handleCopy()}
                    >
                      {isLoadingAddress ? (
                        <div className="skeleton-text">
                          <IonSkeletonText animated />
                          <IonSkeletonText animated />
                        </div>
                      ) : (
                        <span>{address}</span>
                      )}
                      <div className="address-copy">
                        <span className="copy-icon">&#xe916;</span>
                        <span>Copiar</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="wallet-back-buttton" onClick={onReturn}>
            <span>Cerrar</span>
          </div>
        </>
      )}

      <IonToast
        isOpen={openToast}
        message="¡Dirección copiada!"
        onDidDismiss={() => setOpenToast(false)}
        duration={3000}
      />
    </IonContent>
  );
};

export default Wallet;
