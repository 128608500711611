import { FC, useEffect, useState, useRef } from "react";
import "./SelectFilter.scss";

interface Item {
  label: string;
  value: string;
}

interface Props {
  label: string;
  items: Item[];
  highlightedItems?: Item[];
  onSelect: any;
  defaultValue?: string;
  scrollIntoView?: boolean;
}

export const SelectFilter: FC<Props> = ({
  label,
  items,
  onSelect,
  defaultValue,
  scrollIntoView,
  highlightedItems,
}) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [selected, setSelected] = useState<Item>();
  const [filteredItems, setFilteredItems] = useState<Item[]>(items);

  const ref = useRef<any>();

  useEffect(() => {
    setSelected(undefined);
    if (!defaultValue) return;
    const item = items?.find((item: Item) => item.value == defaultValue);
    if (!item) return;
    handleSelect(item);
    setIsVisible(false);
  }, [items]);

  const handleSelect = (item: Item): void => {
    setIsVisible(!isVisible);
    setSelected(item);
    onSelect(item);
  };

  const handleToggle = (): void => {
    if (scrollIntoView)
      ref.current.scrollIntoView({ top: 0, behavior: "smooth" });
    setIsVisible(!isVisible);
    setFilteredItems(items);
  };

  const handleFilterItems = (term: string): void => {
    if (term === "") {
      setFilteredItems(items);
      return;
    }
    let newItems = items.filter((item: Item) =>
      item.label.toLowerCase().includes(term.toLocaleLowerCase())
    );
    if (newItems.length === 0) {
      setFilteredItems(items);
      return;
    }
    setFilteredItems(newItems);
  };

  return (
    <form>
      <label>{label}</label>
      <div className="selfi-scroll-ref" ref={ref} />
      <main className="selfi-container">
        <div className="selfi-selected" onClick={() => handleToggle()}>
          {!selected ? "Seleccione una opcion" : selected.label}
        </div>
        {isVisible && (
          <div className="selfi-list">
            <input
              placeholder="Buscar..."
              onChange={(e) => handleFilterItems(e.target.value)}
            />
            <div className="selfi-options">
              {highlightedItems && (
                <>
                  <p className="selfi-title">Recomendado:</p>
                  {highlightedItems?.map((item: Item) => (
                    <div
                      className="selfi-option"
                      onClick={() => handleSelect(item)}
                    >
                      {item.label}
                    </div>
                  ))}
                  <div className="selfi-divider"></div>
                </>
              )}
              {filteredItems?.map((item: Item) => (
                <div
                  className="selfi-option"
                  onClick={() => handleSelect(item)}
                >
                  {item.label}
                </div>
              ))}
            </div>
          </div>
        )}
      </main>
    </form>
  );
};

export default SelectFilter;
