import React, { useEffect, useRef, useState } from "react";
import { USDC } from "../../../types/export";
import { Swiper, SwiperSlide } from "swiper/react";
import { IonContent, useIonViewDidEnter } from "@ionic/react";
import { Navigation } from "swiper";
import exchangeRateService from "../../../services/Services/ExchangeRateService/ExchangeRateService";
import LoadingView from "../../LoadingView/LoadingView";
import Button from "../../Buttons/Button/Button";
import Title from "../../Title/Title";
import VIIO from "../WithdrawMethods/VIIO/VIIO";
import "swiper/css";
import "./Transfer.scss";
import transactionService from "../../../services/Services/TransactionService/TransactionService";
import IconWrong from "../../../assets/icon/config/WRONG.svg";
import IconEmail from "../../../assets/icon/config/EMAIL.svg";
import { useHistory } from "react-router";
import kycService from "../../../services/Services/KycService/KycService";
import invalidAccessIcon from "../../../assets/images/pup_land/Invalid_access.svg";
import IconSendEmail from "../../../assets/images/pup_land/Email_already.svg";
import { useDispatch } from "react-redux";
import { closeModal, triggerModal } from "../../../store/modalReducer";
import VerificationCodeInput, { VerficationCodeStatuses } from "../../Session/VerificationCodeInput/VerificationCodeInput";
import userAuthMethodService from "../../../services/Services/UserAuthMethod/UserAuthMethod";
import userService from "../../../services/Services/UserService/UserService";
import { validateKYCSuccessAndCompletedStatus } from "../../../services/Tools/KYCVerifierTools";

interface Props {
	home(): void;
}

const Transfer: React.FC<Props> = ({ home }) => {
	const history = useHistory();
	const dispatch = useDispatch();

	const [swaperInstance, setSwiperInstance] = useState<any>();
	const swiperNavPrevRef = useRef<any>();
	const swiperNavNextRef = useRef<any>();
	const topRef = useRef<any>();

	// ? data
	const [rateId, setRateId] = useState<number>(0);
	const [userBalance, setUserBalance] = useState<any>({});
	const [userAuth, setUserAuth] = useState<string>("");
	const [userPin, setUserPin] = useState<string>("");
	const [submitValues, setSubmitValues] = useState<any>("");
	const [limits, setLimits] = useState<any>([]);
	const [transferTotal, setTransferTotal] = useState<any>("");
	const [userCredentials, setUserCredentials] = useState<any>({});

	const [disableButton, setDisableButton] = useState<boolean>(true);
	const [balanceAlert, setBalanceAlert] = useState<string>("");
	const [isLoadingBalance, setIsLoadingBalance] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isLoadingConfirmation, setIsLoadingConfirmation] = useState<boolean>(false);
	const [verificationCodeStatus, setVerificationCodeStatus] = useState(VerficationCodeStatuses.CLEAN)

	useIonViewDidEnter(() => {
		transactionService.updateExternalDeposit();
		if (!swaperInstance) return;
		swaperInstance.slideTo(0);
	});

	useEffect(() => {
		getRate();
		getBalance();
		getTransactionLimits();
	}, []);

	useEffect(() => {
		checkLimits();
	}, [transferTotal, userBalance, limits]);

	const getRate = async () => {
		let rate = await exchangeRateService.getRate("USDC-USDC");
		setRateId(rate.rateId || 0);
	};

	const getBalance = async () => {
		setIsLoadingBalance(true);
		const response = await transactionService.getBalance();
		setUserBalance(response.data);
		setIsLoadingBalance(false);
	};

	const getTransactionLimits = async () => {
		const response = await exchangeRateService.getAssetsLimit();
		setLimits(response);
	};

	const getUserCredentials = async () => {
		const response: any = await userService.getExternalUserCredentials(
			submitValues.email
		);
		const user = response?.data;
		setUserCredentials(user || {});
	};

	const checkLimits = () => {
		if (parseFloat(userBalance?.availableBalance) < transferTotal) {
			setBalanceAlert("Saldo insuficiente");
			return;
		}
		const limit = limits.find(
			(item: any) => item["Assets.details"]?.symbol == "VIIO"
		);
		if (transferTotal < limit?.minLimit) {
			setBalanceAlert(`Monto mínimo: ${limit?.minLimit} USDC`);
			return;
		}
		if (transferTotal > limit?.maxLimit) {
			setBalanceAlert(`Monto maximo: ${limit?.maxLimit} USDC`);
			return;
		}
		setBalanceAlert("");
	};

	const changeSlide = (swaperInstance: any, event: string) => {
		topRef.current.scrollIntoView({ behavior: "smooth" });
		switch (event) {
			case "next":
				swaperInstance.slideNext(500);
				break;
			case "prev":
				swaperInstance.slidePrev(500);
				break;
		}
	};

	const checkAvailability = async () => {
		const responses: any = {
			"User is not available for transfer":
				"La cuenta a no se encuentra disponible",
		};
		setIsLoadingConfirmation(true);
		const response = await userAuthMethodService.checkUserAvailability(
			submitValues.email
		);
		setIsLoadingConfirmation(false);
		if (!response.data?.available || !response.data) {
			dispatch(
				triggerModal({
					icon: IconSendEmail,
					title: "No es posible realizar la transferencia",
					content:
						responses[response.data?.message || ""] || "El usuario no existe",
					onClick: () => dispatch(closeModal()),
				})
			);
			return;
		}
		changeSlide(swaperInstance, "next");
		//handleAuthMethod();
	};

	const handleAuthMethod = () => {
		if (userAuth === "pin") {
			generatePin();
			return;
		}
		changeSlide(swaperInstance, "next");
	};

	const generatePin = async () => {
		const response = await userAuthMethodService.generateUserPin();
		if (!response.succesful) {
			dispatch(
				triggerModal({
					icon: invalidAccessIcon,
					title: "No se ha podido generar el pin",
					content: "Ha ocurrido un error al generar el pin de verificacion.",
					onClick: () => { setVerificationCodeStatus(VerficationCodeStatuses.CLEAN); return dispatch(closeModal()) },
				})
			);
			return;
		}
		dispatch(
			triggerModal({
				icon: IconSendEmail,
				title: "Código de seguridad",
				content:
					"Hemos enviado un código de seguridad a tu correo electrónico. Verifica tu bandeja de entrada",
				label: "Entendido",
				onClick: () => {
					dispatch(closeModal());
					setVerificationCodeStatus(VerficationCodeStatuses.CLEAN);
					changeSlide(swaperInstance, "next");
				},
			})
		);
	};

	const verifyUser = async () => {
		if (userAuth === "pin") {
			verifyPin();
			setVerificationCodeStatus(VerficationCodeStatuses.CLEAN)
			return;
		}
		verifyOtp();
	};

	const verifyOtp = async () => {
		const response = await userAuthMethodService.verifyUserOtp({
			otp: parseInt(userPin),
		});
		if (!response.succesful) {
			dispatch(
				triggerModal({
					icon: invalidAccessIcon,
					title: "Código OTP incorrecto",
					content: "Verifica el código OTP para realizar un retiro.",
					onClick: () => dispatch(closeModal()),
				})
			);
			return;
		}
		changeSlide(swaperInstance, "next");
	};

	const verifyPin = async () => {
		const response = await userAuthMethodService.verifyUserPin({
			code: parseInt(userPin),
		});
		if (!response.succesful) {
			dispatch(
				triggerModal({
					icon: invalidAccessIcon,
					title: "Pin incorrecto",
					content: "Verifica el pin para realizar un retiro.",
					onClick: () => dispatch(closeModal()),
				})
			);
			return;
		}
		handleSubmit();
	};

	const handleGetData = (data: any) => {
		setDisableButton(!data.isValid);
		setSubmitValues(data.data);
	};

	const handleInputChange = (value: string): void => {
		if (!/^[0-9]*(\.[0-9]{0,2})?$/.test(value)) return;
		setTransferTotal(value);
	};



	const checkKycStatus = async () => {
		getUserCredentials();
		const kycStatus = await kycService.verifyKycStatus();
		if (!validateKYCSuccessAndCompletedStatus(kycStatus)) {
			dispatch(
				triggerModal({
					icon: invalidAccessIcon,
					title: "No estás verificado",
					content:
						"Realiza la verificación de tu identidad para usar VIIO. Toma solo unos minutos.",
					onClick: () => {
						dispatch(closeModal());
						history.push("/dashboard/config");
					},
				})
			);
			return;
		}
		getUserAuth();
	};

	const getUserAuth = async () => {
		const response = await userAuthMethodService.getUserAuthMethod();
		if (!response.data) {
			dispatch(
				triggerModal({
					icon: invalidAccessIcon,
					title: "No es posible completar la transaccion",
					content: "Debes configurar un método de autenticación.",
					onClick: () => {
						dispatch(closeModal());
						history.push("/security");
					},
				})
			);
			return;
		}
		setUserAuth(response.data);
		checkAvailability();
	};

	const handleSubmit = async () => {
		setIsLoading(true);
		const response = await transactionService.createViioTransfer({
			timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
			total: transferTotal,
			rateId: rateId,
			email: submitValues.email,
			payload: { ...submitValues },
		});
		setIsLoading(false);
		const isCompleted = response.data?.status == "Success";
		dispatch(
			triggerModal({
				icon: isCompleted ? IconEmail : IconWrong,
				title: isCompleted ? "Envío exitoso" : "Lo sentimos",
				content: isCompleted
					? "Recuerda que las transacciones de VIIO a VIIO son inmediatas."
					: "No se pudo completar el retiro.",
				onClick: () => dispatch(closeModal()),
			})
		);
		if (!isCompleted) return;
		setTimeout(() => {
			history.push("/dashboard/viio");
		}, 3000);
	};

	return (
		<IonContent className="ion-content">
			<div className="top-reference" ref={topRef} />
			<Swiper
				modules={[Navigation]}
				navigation={{
					prevEl: swiperNavPrevRef.current,
					nextEl: swiperNavNextRef.current,
				}}
				slidesPerView={1}
				spaceBetween={400}
				allowTouchMove={false}
				onInit={(swiper: any) => {
					swiper.params.navigation.prevEl = swiperNavPrevRef.current;
					swiper.params.navigation.nextEl = swiperNavNextRef.current;
					swiper.navigation.init();
					swiper.navigation.update();
				}}
				onSwiper={(swiper) => setSwiperInstance(swiper)}
			>
				<SwiperSlide>
					<div className="swiper-slide__container">
						<Title title={"Enviar"} arrows={[true, false]} backClick={home} />
						<div className="content-container">
							<div>
								<div className="pair-input">
									<form>
										<VIIO handleGetData={(data) => handleGetData(data)} />
										<label className="pair-input__title title-light">
											{"*Cantidad de USDC a transferir"}
										</label>
										<div className="pair-input__box small">
											<div className="pair-input__box-icon">
												<img src={USDC} alt="" />
											</div>
											<div className="pair-input__box-input small">
												<input
													placeholder="0"
													autoComplete="off"
													onChange={(e) => handleInputChange(e.target.value)}
													value={transferTotal}
												/>
											</div>
											<div className="pair-input__box-currency">
												<p>USDC</p>
											</div>
										</div>
									</form>
								</div>
							</div>
							<div>
								<div className="msg_alert">
									<span className="warning">{balanceAlert}</span>
								</div>
								<div
									className={`custom-buttton ${balanceAlert !== "" ||
										disableButton ||
										isLoadingConfirmation
										? "disabled"
										: ""
										} `}
									onClick={() => checkKycStatus()}
								>
									<span>Enviar</span>
								</div>
							</div>
						</div>
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div className="swiper-slide__container">
						<Title
							title="Confirmar información"
							arrows={[true, false]}
							backClick={() => changeSlide(swaperInstance, "prev")}
						/>
						<div className="content-container">
							<div className="user__content">
								<div className="user__item">
									<span>USDC A ENVIAR:</span>
									<span>${transferTotal} USDC</span>
								</div>
								<div className="user__item">
									<span>COMISIÓN:</span>
									<span>$0.00 USDC</span>
								</div>
								<br />
								<div className="user__item">
									<span>NOMBRE DE RECEPTOR:</span>
									<span>{userCredentials.name}</span>
								</div>
								<div className="user__item">
									<span>CORREO ELECTRÓNICO:</span>
									<span>{submitValues.email}</span>
								</div>
							</div>
							<div>
								<div
									className={`custom-buttton colored ${disableButton || isLoadingConfirmation ? "disabled" : ""
										} `}
									onClick={() => handleAuthMethod()}
								>
									<span>Confirmar y enviar</span>
								</div>
								<div
									className={`custom-buttton ${disableButton || isLoadingConfirmation ? "disabled" : ""
										} `}
									onClick={() => changeSlide(swaperInstance, "prev")}
								>
									<span>Corregir</span>
								</div>
							</div>
						</div>
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div className="swiper-slide__container">
						<Title
							title="Confirmar código de verificación"
							arrows={[true, false]}
							backClick={() => {
								changeSlide(swaperInstance, "prev");
							}}
						/>
						<div className="content-container">
							<form>
								<VerificationCodeInput
									handleGetCode={(pin: string) => setUserPin(pin)}
									cleanSetCode={setVerificationCodeStatus}
									cleanStatus={verificationCodeStatus}
									length={userAuth === "pin" ? 4 : 6}
								/>
							</form>

							<div
								className={`custom-buttton ${isLoading ? "disabled" : ""} `}
								onClick={() => verifyUser()}
							>
								<span>Enviar</span>
							</div>
						</div>
					</div>
				</SwiperSlide>
			</Swiper>
		</IonContent>
	);
};

export default Transfer;
