import { IonContent, isPlatform, useIonViewDidEnter, useIonViewDidLeave } from "@ionic/react";
import { CLIENT_ID, FLOW_ID, FLOW_ID_TEST, INTERCOM_FLOW_ID, TESTING } from "../../../services/BackendServicesExports";
import { closeModal, triggerModal } from "../../../store/modalReducer";
import { useEffect, useRef, useState } from "react";
import { VerficationStatus as VerificationStatus } from "../../../services/Services/KycService/KycModels";
import { MetaMapCapacitor } from "metamap-capacitor-plugin";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import emailService from "../../../services/Services/EmailService/EmailService";
import loginService from "../../../services/Services/LoginService/LoginService";
import ButtonLink from "../../Buttons/ButtonLink/ButtonLink";
import kycService from "../../../services/Services/KycService/KycService";
import userService from "../../../services/Services/UserService/UserService";
import IconLogout from "../../../assets/images/pup_land/Log_out.svg";
import React from "react";
import "./Config.scss";
import { validateKYCSuccessAndCompletedStatus } from "../../../services/Tools/KYCVerifierTools";

interface ConfigProps {
    securityButton: any;
    handleGoToWallet: any;
    redirectToLogin(): void;
}

const Config: React.FC<ConfigProps> = ({ securityButton, redirectToLogin, handleGoToWallet }) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const [isMetamapButtonDisabled, setIsMetamapButtonDisabled] = useState<boolean>(true);

    const [credentials, setCredentials] = useState<any>();
    const [verificationStatus, setVerificationStatus] = useState<VerificationStatus>(VerificationStatus.LOADING);
    
    useIonViewDidLeave(()=>{
        window.Intercom("hide")
    })

    useIonViewDidEnter(() => {
        verifyStatus();
        setIsMetamapButtonDisabled(false);
    });

    useEffect(() => {
        window.Intercom("boot", { app_id: INTERCOM_FLOW_ID });
        initMetamap();
        getCredentials();
    }, []);

    const addTryToMetaKycProcess = async () => {
        setIsMetamapButtonDisabled(true);
        await kycService.updateKycStatusAddingValidationTry()
    }

    const metaKycProcessFulfilled = async () => {
        setVerificationStatus(VerificationStatus.LOADING);
        setIsMetamapButtonDisabled(true);
        await kycService.updateKycStatusToFinished();
        setVerificationStatus(VerificationStatus.FILLED);
        await verifyStatus();
        await emailService.sendMetamapVerificationInProcessEmail();
        setIsMetamapButtonDisabled(false);
    }

    const metaKycProcessExited = async () => {
        setVerificationStatus(VerificationStatus.LOADING);
        const kycStatus = await kycService.verifyKycStatus();
        const tries = kycStatus.kyc.metadata.tries || undefined;
        if((tries && tries > 2)){
            await kycService.blockKycStatusForUser()
            setVerificationStatus(VerificationStatus.REJECTED);
        } else {
            setVerificationStatus(kycStatus.status);
        }
        setIsMetamapButtonDisabled(false);
    }

    const initMetamap = () => {
        document.getElementById("metamap")?.addEventListener("mati:userStartedSdk", async ({ detail }: any) => {
            await addTryToMetaKycProcess();
        });
        document.getElementById("metamap")?.addEventListener("mati:userFinishedSdk", async ({ detail }: any) => {
            await metaKycProcessFulfilled();
        });
        document.getElementById("metamap")?.addEventListener("mati:exitedSdk", async ({ detail }: any) => {
            await metaKycProcessExited();
        });
    };

    const verifyStatus = async () => {
        try {
            const { status, kyc } = await kycService.verifyKycStatus();
            if(validateKYCSuccessAndCompletedStatus({kyc})) 
                setVerificationStatus(status)
            else {
                if(status === VerificationStatus.VERIFIED)
                    setVerificationStatus(VerificationStatus.REVIEWNEDDED)
                else
                    setVerificationStatus(status)
            }            
        } catch (e) {
            console.log(e);
        }
    };

    const getCredentials = async () => {
        const response: any = await userService.getUserCredentials();
        setCredentials(response?.data);
        updateIntercom(response?.data);
    };

    const updateIntercom = (credentials: any) => {
        //console.log("updating intercom", credentials)
        window.Intercom("update", {
            email: credentials?.email,
            user_id: credentials?.userId || credentials?.user_id,
            name: `${credentials?.firstName}  ${credentials?.lastName}`,
            phone: credentials?.phone,
        });
    };

    const handleVerificationButton = () => {
        switch (verificationStatus) {
            case VerificationStatus.VERIFIED:
                history.push("/user");
                break;
            case VerificationStatus.PENDING:
                launchMetamap();
                break;
            default:
                break;
        }
    };

    const verificationStatusLabel = () => {
        switch (verificationStatus) {
            case VerificationStatus.FILLED:
            case VerificationStatus.FINISHED:
                return "Verificacion en proceso";
            case VerificationStatus.VERIFIED:
                return "Mis Datos";
            case VerificationStatus.REJECTED:
                return "Verificación Rechazada";
            case VerificationStatus.POSTPONED:
            case VerificationStatus.REVIEWNEDDED:
                return "Verificación Postpuesta";
            case VerificationStatus.LOADING:
                return "Cargando"
            case VerificationStatus.PENDING:
            default:
                return "Verificar Identidad";
        }
    };

    const launchMetamap = () => {
        if (isPlatform("desktop") || isPlatform("mobileweb")) metamapDesktop();
        else showMatiFlow();
    };

    const launchIntercom = () => {
        window.Intercom("show", { app_id: INTERCOM_FLOW_ID });
    };

    const showMatiFlow = () => {
        let metadataParams = { fixedLanguage: "es", email: `${credentials?.email}` };
        let registerParams = {
            clientId: CLIENT_ID,
            flowId: TESTING ? FLOW_ID_TEST : FLOW_ID,
            metadata: metadataParams,
        };

        addTryToMetaKycProcess().then(()=>{
            MetaMapCapacitor.showMetaMapFlow(registerParams)
            .then(async (verification) => {
                console.log("verification success:" + verification.verificationID)
                await metaKycProcessFulfilled()
            })
            .catch(async () => {
                console.log("verification cancelled")
                await metaKycProcessExited();
            });
        })        
    };

    const metamapDesktop = () => {
        document.getElementById("metamap")?.click();
        console.log("metamapDesktop:", document.getElementById("metamap"));
    };

    const logout = async () => {
        dispatch(
            triggerModal({
                icon: IconLogout,
                title: "Cerrarás sesión en este dispositivo",
                content: "¿Seguro que quiere cerrar sesión?",
                altButtonLabel: "Cancelar",
                onClick: () => handleLogout(),
                onAltClick: () => dispatch(closeModal()),
            })
        );
    };

    const handleLogout = async () => {
        redirectToLogin();
        dispatch(closeModal());
        window.Intercom("shutdown");
        await loginService.logout();
    };

    return (
        <IonContent>
            <div style={{ display: "none" }}>
                <mati-button
                    clientId={CLIENT_ID}
                    flowId={TESTING ? FLOW_ID_TEST : FLOW_ID}
                    metadata={JSON.stringify({
                        fixedLanguage: "es",
                        email: `${credentials?.email}`,
                    })}
                    id="metamap"
                />
            </div>
            <div className="config">
                <div className="config__data">
                    <h2 className="config__data-title">Configuración</h2>
                </div>
                <div className="config__btns">
                    <ButtonLink
                        mode={""}
                        label={verificationStatusLabel()}
                        outline="both"
                        icon="&#xe907;"
                        both={true}
                        disable={isMetamapButtonDisabled}
                        onClick={handleVerificationButton}
                    />
                    <ButtonLink
                        mode={""}
                        label={"Mi dirección de billetera (USDC)"}
                        icon="&#xe915;"
                        both={true}
                        disable={!(verificationStatus === VerificationStatus.VERIFIED)}
                        onClick={handleGoToWallet}
                    />
                    <ButtonLink mode={""} label={"Seguridad"} outline="both" icon="&#xe908;" onClick={securityButton} />
                    <ButtonLink mode={""} label={"Ayuda"} outline="bottom" icon="&#xe905;" onClick={launchIntercom} />
                    <a
                        href="https://drive.google.com/file/d/13Zft4jiTZxBstWta6gZISYF4D5yGDpey/view?usp=drive_link"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <ButtonLink mode={""} label={"Terminos y condiciones"} outline="bottom" icon="&#xe909;" />
                    </a>
                    <ButtonLink
                        mode={""}
                        label={"Cerrar Sesion"}
                        outline="bottom"
                        icon="&#xe900;"
                        onClick={() => {
                            logout();
                        }}
                    />
                </div>
            </div>
        </IonContent>
    );
};

export default Config;
