import { FC, useState, useEffect } from "react";
import nonEmptyVerifier from "../../../../services/Verifiers/NonEmptyVerifier";
import nonSelectedVerifier from "../../../../services/Verifiers/NonSelectedVerfier";
import accountVerifier from "../../../../services/Verifiers/AccountVerifier";
import { CheckType } from "../../../../services/Verifiers/Verfier";
import Validator from "../../../Validator/Validator";
import transitNumberVerifier from "../../../../services/Verifiers/TransitNumberVerifier";
import institutionNumberVerifier from "../../../../services/Verifiers/InstitutionNumberVerifier";
import digitVerfier from "../../../../services/Verifiers/DigitVerifier";

interface CADProps {
  handleGetData: (data: any) => any;
  defaultValues?: any;
}

const CAD: FC<CADProps> = ({ handleGetData, defaultValues }) => {
  const [accountNumber, setAccountNumber] = useState<any>({
    type: CheckType.INIT,
    message: "",
  });
  const [accountType, setAccountType] = useState<any>({
    type: CheckType.INIT,
    message: "",
  });
  const [transitNumber, setTransitNumber] = useState<any>({
    type: CheckType.INIT,
    message: "",
  });
  const [institutionNumber, setInstitutionNumber] = useState<any>({
    type: CheckType.INIT,
    message: "",
  });

  const [submit, setSubmit] = useState<any>({});

  useEffect(() => {
    checkData();
  }, [submit]);

  const handleAccountNumber = (target: any) => {
    target.value = /^[0-9]+$/.test(target.value) ? target.value: target.value.slice(0,-1);
    let checker: any = {};
    if (target.value === "")
      checker = nonEmptyVerifier.check(target.value, "el numero de cuenta");
    else checker = accountVerifier.checkCAD(target.value);
    setAccountNumber(checker);
    saveSubmit(target.value, target.name);
  };

  const handleAccountType = (target: any) => {
    let checker = nonSelectedVerifier.check(target.value, "el tipo de cuenta");
    setAccountType(checker);
    saveSubmit(target.value, target.name);
  };

  const handleTransitNumber = (target: any) => {
    target.value = /^[0-9]{0,5}$/.test(target.value) ? target.value: target.value.slice(0,-1);
    if (!digitVerfier.isValid(target.value)) return;
    let checker: any = {};
    if (target.value === "")
      checker = nonEmptyVerifier.check(target.value, "el numero de transito");
    else checker = transitNumberVerifier.check(target.value);
    setTransitNumber(checker);
    saveSubmit(target.value, target.name);
  };

  const handleInstitutionNumber = (target: any) => {
    target.value = /^[0-9]{0,3}$/.test(target.value) ? target.value: target.value.slice(0,-1);
    if (!digitVerfier.isValid(target.value)) return;
    let checker: any = {};
    if (target.value === "")
      checker = nonEmptyVerifier.check(
        target.value,
        "el numero de institucion"
      );
    else checker = institutionNumberVerifier.check(target.value);
    setInstitutionNumber(checker);
    saveSubmit(target.value, target.name);
  };

  const saveSubmit = (value: any, key: string) => {
    const copy = submit;
    copy[key] = value;
    setSubmit({ ...submit });
  };

  const checkData = () => {
    const options = [
      accountNumber,
      accountType,
      transitNumber,
      institutionNumber,
    ];
    const isValid = options.every(
      (option: any) => option.type === CheckType.VALID
    );
    handleGetData({
      isValid: isValid,
      data: {
        ...submit,
        accountHolderName: `${defaultValues?.firstName} ${defaultValues?.lastName}`,
      },
    });
  };

  return (
    <form>
      <div>
        <label>{`Nombre del titular`}</label>
        <input
          disabled
          type="text"
          value={`${defaultValues?.firstName} ${defaultValues?.lastName}`}
        />
        <Validator showAlert={false} message={""} type={""} />
      </div>
      <div>
        <label>{`*N° Transito`}</label>
        <input
          value={submit.transit_number}
          required
          name={"transitNumber"}
          onChange={(e) => handleTransitNumber(e.target)}
        />
        <Validator
          showAlert={transitNumber.type !== CheckType.VALID}
          message={transitNumber.message}
          type={transitNumber.type}
        />
      </div>
      <div>
        <label>{`*N° Institucion`}</label>
        <input
          value={submit.institution_number}
          required
          name={"institutionNumber"}
          onChange={(e) => handleInstitutionNumber(e.target)}
        />
        <Validator
          showAlert={institutionNumber.type !== CheckType.VALID}
          message={institutionNumber.message}
          type={institutionNumber.type}
        />
      </div>
      <div>
        <label>{`*Tipo de cuenta`}</label>
        <select
          required
          name={"accountType"}
          onChange={(e) => handleAccountType(e.target)}
        >
          <option value={""}>{"Seleccione el tipo de cuenta"}</option>
          <option value={"1"}>Cuenta De Ahorros</option>
          <option value={"2"}>Cuenta Corriente</option>
        </select>
        <Validator
          showAlert={accountType.type !== CheckType.VALID}
          message={accountType.message}
          type={accountType.type}
        />
      </div>
      <div>
        <label>{`*Número de cuenta`}</label>
        <input
          required
          name="accountNumber"
          onChange={(e) => handleAccountNumber(e.target)}
        />
        <Validator
          showAlert={accountNumber.type !== CheckType.VALID}
          message={accountNumber.message}
          type={accountNumber.type}
        />
      </div>
    </form>
  );
};

export default CAD;
